import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getOvdTobaccoColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('common.labels.iin'),
  },
  {
    name: 'ovdCode',
    label: loc('ovd.code'),
  },
  {
    name: 'kogd',
    label: loc('ovd.kogd'),
  },
  {
    name: 'address',
    label: loc('ovd.address'),
  },
  {
    name: 'messageType',
    label: loc('ovd.message_type'),
    getContent: (row) => (row.messageType ? loc(`ovd.messageType.${row.messageType}`) : ''),
  },
];
