import { FilterContentProps } from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput } from 'ui/common';

type Props = FilterContentProps;

export const PincodesTobaccoArmFilters: React.FC<Props> = ({ form, classNames: { columnClassName } }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="tin" label={loc('pincodes.table.title.tobacco_tin')}>
          <FormInput />
        </FormField>
        <FormField name="pinCode" label={loc('pincodes.table.title.pinCode')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="productName" label={loc('pincodes.table.title.tobacco_productName')}>
          <FormInput />
        </FormField>
        <FormField name="mark" label={loc('pincodes.tobacco.table.title.mark')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="quantityInPack" label={loc('pincodes.tobacco.table.title.quantityInPack')}>
          <FormInput />
        </FormField>
      </div>
    </Fragment>
  );
};
