import { FilterContentProps } from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput } from 'ui/common';

type Props = FilterContentProps;

export const PincodesOilArmFilters: React.FC<Props> = ({ form, classNames: { columnClassName } }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <Fragment>
      <div className={columnClassName} style={{ width: '35%' }}>
        <FormField name="tin" label={loc('pincodes.table.title.oil_tin')}>
          <FormInput />
        </FormField>
        <FormField name="pinCode" label={loc('pincodes.table.title.pinCode')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="productName" label={loc('common.labels.productName')}>
          <FormInput />
        </FormField>
        <FormField name="productionType" label={loc('pincodes.table.title.productType')}>
          <FormInput />
        </FormField>
      </div>
    </Fragment>
  );
};
