import { JournalButton, Journal } from 'common/components/journal';
import { solveErrorMessage } from 'common/form/helpers';
import { useStore } from 'common/hooks';
import React, { Fragment, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { queryObjectsOvdTobaccoSearch } from '../api/queriesOvdTobaccoTaxpayer';
import {
  mutationAdminDictionaryOvdTobaccoImportFromFile,
  queryAdminDictOvdTobaccoExport,
} from '../api/queriesOvdTobaccoArm';
import { SessionStorageService } from 'services/storage/StorageService';
import { ToastService } from 'services/toasts';
import { rowsOptionsTaxpayerFull } from 'ui';
import { OvdTobaccoArmFiltersAdvanced } from './OvdTobaccoArmFilterAdvanced';
import { getOvdTobaccoColumns } from './getOvdTobaccoColumns';
import { ADMIN_PERMISSION } from 'common/constants';
import { ImportFromFileModal } from 'common/components/modals/import-from-file';
import { Modal } from 'services/modal';
import { OvdTobaccoArmEditModal } from './ovd-tobacco-edit-modal/OvdTobaccoArmEditModal';
import { getResourceUrl } from 'common/helpers';
import { queryDictionaryOvdTobaccoImportResult } from '../api/queriesOvdTobaccoArm';
import { mutationAdminDictionaryOvdTobaccoUploadFile } from '../api/queriesOvdTobaccoArm';

const initialPagination = { rows: 15 };
const initialOrder = 'desc';

const getRowId = (row: any) => row.id;
type editModalType = { show: 'add'; row: any } | { show: 'edit'; row: any } | { show: 'view'; row: any };
const getRows = (data: any) => (Array.isArray(data?.rows) ? data.rows : undefined);

const filterStorageKey = 'objectsOvdOilArmFilter';

export const OvdTobaccoArmPage: React.FC = () => {
  const { locale, user } = useStore();
  const { loc } = locale;

  const [editModal, setEditModal] = useState<undefined | editModalType>(undefined);
  const [importModal, setImportModal] = useState<boolean>(false);
  const columns = useMemo(() => getOvdTobaccoColumns({ locale }), [locale]);

  const initialFilter = useMemo(() => SessionStorageService.get(filterStorageKey) || {}, []);

  const queryProps = useDataQuery(
    queryObjectsOvdTobaccoSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      order: initialOrder,
    },
    {
      preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }),
    }
  );

  const exportJournal = async () => {
    try {
      const res = await queryAdminDictOvdTobaccoExport({
        pagination: queryProps.pagination,
        filter: queryProps.filter,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 'add',
      label: loc('common.button.add'),
      onClick: () => setEditModal({ show: 'add', row: null }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.TOBACCO_OVD_VIEW),
    },
    {
      id: 'export',
      label: loc('common.button.exportExcel'),
      onClick: () => exportJournal(),
    },
    {
      id: 'import',
      label: loc('common.button.import'),
      onClick: () => setImportModal(true),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.TOBACCO_OVD_EDIT),
    },
  ];

  const onCloseEditModal = (needRefetch?: boolean) => {
    setEditModal(undefined);
    if (needRefetch) {
      queryProps.refetch();
    }
  };

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.viewing'),
      onClick: (selected) => setEditModal({ show: 'view', row: selected[0] }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.TOBACCO_OVD_VIEW),
    },
    {
      id: 2,
      label: loc('common.button.edit'),
      onClick: (selected) => setEditModal({ show: 'edit', row: selected[0] }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.TOBACCO_OVD_VIEW),
    },
  ];

  const onCloseImportModal = () => {
    queryProps.refetch();
    setImportModal(false);
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('admin.menu.TOBACCO_OVD')}
        columns={columns}
        filterInitialOpened
        queryProps={queryProps}
        getRows={getRows}
        filterExpandable
        filterComponent={OvdTobaccoArmFiltersAdvanced}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        paginationRowsOptions={rowsOptionsTaxpayerFull}
      />
      {!!editModal && (
        <Modal scrollLock={false} isOpen={true} onClose={onCloseEditModal} marginTop={20}>
          <OvdTobaccoArmEditModal data={editModal.row} mode={editModal.show} />
        </Modal>
      )}
      <Modal isOpen={importModal} onClose={onCloseImportModal}>
        <ImportFromFileModal
          onSubmit={mutationAdminDictionaryOvdTobaccoImportFromFile}
          uploadFile={async (files: File[]) => {
            await mutationAdminDictionaryOvdTobaccoUploadFile({ body: { file: files[0] } });
            return undefined;
          }}
          downloadResult={queryDictionaryOvdTobaccoImportResult}
          exampleLink={getResourceUrl('/data/downloads/Табак овд.xlsx')}
        />
      </Modal>
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => {
  return filter;
};
