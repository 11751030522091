import { JournalButton, JournalT2 } from 'common/components/journal';
import { solveErrorMessage } from 'common/form/helpers';
import { getLegasyUrl } from 'common/helpers';
import { useStore } from 'common/hooks';
import React, { Fragment, useMemo } from 'react';
import { useDataQuery } from 'rest';
import { queryDictPinAlcoholSearch, queryDictPinAlcoholExport } from '../api';
import { SessionStorageService } from 'services/storage/StorageService';
import { ToastService } from 'services/toasts';
import { rowsOptionsTaxpayerFull } from 'ui';
import { PincodesAlcoholFiltersAdvanced } from './PincodesAlcoholFiltersAdvanced';
import { PincodesAlcoholFiltersMain } from './PincodesAlcoholFiltersMain';
import { ReactComponent as DownloadIcon } from 'assets/icons/journal-buttons/download.svg';
import { getPincodesAlcoholColumns, getPincodesAlcoholFilterTags } from './getPincodesAlcoholColumns';
import styles from './PincodesAlcohol.module.scss';

const initialPagination = { rows: 15 };
const initialOrder = 'desc';

const getRowId = (row: any) => row.id;

const getRows = (data: any) => (Array.isArray(data?.rows) ? data.rows : undefined);

const filterStorageKey = 'pincodesAlcoholTaxpayerFilter';

type Props = {};

const PincodesAlcoholTaxpayerPage: React.FC<Props> = () => {
  const { locale } = useStore();
  const { loc } = locale;

  const columns = useMemo(() => getPincodesAlcoholColumns({ locale }), [locale]);

  const filterTags = useMemo(() => getPincodesAlcoholFilterTags({ locale }), [locale]);

  const initialFilter = useMemo(() => SessionStorageService.get(filterStorageKey) || {}, []);

  const queryProps = useDataQuery(
    queryDictPinAlcoholSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      order: initialOrder,
    },
    {
      preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }),
    }
  );

  const headerButtons: Array<JournalButton> = [
    {
      id: 'export',
      label: loc('common.button.exportExcel'),
      icon: DownloadIcon,
      onClick: () => {
        return queryDictPinAlcoholExport({ filter: preProcessFilter(queryProps.filter) }).catch((error) => {
          ToastService.showError(solveErrorMessage(error));
        });
      },
      variant: 'icon',
    },
  ];

  return (
    <Fragment>
      <JournalT2
        pageTitle={loc('common.menu.ALCOHOL_PIN')}
        columns={columns}
        queryProps={queryProps}
        getRows={getRows}
        filterComponentMain={PincodesAlcoholFiltersMain}
        filterComponentAdvanced={PincodesAlcoholFiltersAdvanced}
        headerButtons={headerButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        filterTags={filterTags}
        filterPanelType="aside"
        settingsModalWidth={520}
        showTotalRow
        paginationRowsOptions={rowsOptionsTaxpayerFull}
        oldVersionLink={{
          label: loc('common.oldVersion'),
          external: true,
          url: getLegasyUrl('/dictionaries/pin-alcohol'),
        }}
        className={styles.container}
      />
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => {
  return filter;
};

export default PincodesAlcoholTaxpayerPage;
