import { DataQueryFunction, downloadFile, httpClient, toPaginationParams } from 'rest';
import { clearEmptyFields } from 'common/utils';
import { API_URL } from 'common/constants';

export const queryDictLicenseAlcoholSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination, order, sort } = variables || {};

  let criteria: any = JSON.stringify(clearEmptyFields({ ...filter }));

  const res = await httpClient.get(`dict/license/alcohol/search`, {
    params: clearEmptyFields({
      criteria,
      ...toPaginationParams(pagination),
      order,
      sort,
    }),
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};

export const queryDictLicenseAlcoholExport: DataQueryFunction = (variables: any) => {
  const { filter = {}, pagination } = variables;
  return downloadFile({
    url: `${API_URL}/dict/license/alcohol/export`,
    method: 'GET',
    params: {
      criteria: JSON.stringify(clearEmptyFields(filter)),
      ...pagination,
    },
  });
};
