import { JournalButton, Journal } from 'common/components/journal';
import { findErrorData } from 'common/form/helpers';
import { useStore } from 'common/hooks';
import React, { Fragment, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import {
  queryDictPinOilSearch,
  queryAdminDictPinOilExport,
  queryAdminDictPinOilImportFile,
  queryAdminDictPinOilUploadFile,
  queryAdminDictPinOilImportResult,
} from '../api';
import { SessionStorageService } from 'services/storage/StorageService';
import { ToastService } from 'services/toasts';
import { rowsOptionsTaxpayerFull } from 'ui';
import { PincodesOilArmFilters } from './PincodesOilArmFilters';
import { ReactComponent as XlsIcon } from 'assets/icons/journal-buttons/export-xls.svg';
import { ReactComponent as NewIcon } from 'assets/icons/journal-buttons/new.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/journal-buttons/upload.svg';
import { getPincodesOilColumns } from './getPincodesOilColumns';
import styles from './PincodesOilArm.module.scss';
import { PincodesOilFormArmModal } from './components/form-modal/PincodesOilFormArmModal';
import { ADMIN_PERMISSION } from '../../../common/constants';
import { ImportFromFileModal } from '../../../common/components';
import { getResourceUrl } from '../../../common/helpers';
import { Modal } from '../../../services/modal';

const initialPagination = { rows: 15 };
const initialOrder = 'desc';
const getRowId = (row: any) => row.id;
const getRows = (data: any) => (Array.isArray(data?.rows) ? data.rows : undefined);

const filterStorageKey = 'pincodesOilArmFilter';

type Props = {};
type editModalType = { show: 'add'; row: any } | { show: 'edit'; row: any } | { show: 'view'; row: any };

const PincodesOilArmPage: React.FC<Props> = () => {
  const { locale, user } = useStore();
  const { loc } = locale;
  const [importModal, setImportModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<undefined | editModalType>(undefined);

  const columns = useMemo(() => getPincodesOilColumns({ locale }), [locale]);

  const initialFilter = useMemo(() => SessionStorageService.get(filterStorageKey) || {}, []);

  const queryProps = useDataQuery(
    queryDictPinOilSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      order: initialOrder,
    },
    {
      preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }),
    }
  );

  const headerButtons: Array<JournalButton> = useMemo(
    () => [
      {
        id: 'create',
        label: loc('pincodes.oil.button.add'),
        icon: NewIcon,
        disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.OIL_PIN_EDIT),
        onClick: () => setEditModal({ show: 'add', row: null }),
        variant: 'primary',
      },
      {
        id: 'export',
        label: loc('common.button.exportExcel'),
        icon: XlsIcon,
        onClick: () => {
          queryAdminDictPinOilExport({
            pagination: queryProps.pagination,
            filter: preProcessFilter(queryProps.filter),
          }).catch((error) => {
            const message = findErrorData(error);
            message?.length && ToastService.showError(message);
          });
        },
        variant: 'icon',
      },
      {
        id: 'import',
        label: loc('common.button.import'),
        icon: UploadIcon,
        onClick: () => setImportModal(true),
        variant: 'icon',
      },
    ],
    [queryProps, loc, user, setEditModal]
  );

  const actionButtons = useMemo(
    (): JournalButton[] => [
      {
        id: 1,
        label: loc('common.button.view'),
        onClick: (selected) => setEditModal({ show: 'view', row: selected[0] }),
      },
      {
        id: 2,
        label: loc('common.button.edit'),
        onClick: (selected) => setEditModal({ show: 'edit', row: selected[0] }),
      },
    ],
    [loc, setEditModal]
  );

  const onCloseImportModal = () => {
    queryProps.refetch();
    setImportModal(false);
  };

  const onCloseEditModal = (needRefetch?: boolean) => {
    setEditModal(undefined);
    if (needRefetch) {
      queryProps.refetch();
    }
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('common.menu.OIL_PIN')}
        filterInitialOpened
        columns={columns}
        queryProps={queryProps}
        getRows={getRows}
        filterComponent={PincodesOilArmFilters}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        paginationRowsOptions={rowsOptionsTaxpayerFull}
        className={styles.container}
      />
      <Modal isOpen={importModal} onClose={onCloseImportModal}>
        <ImportFromFileModal
          onSubmit={queryAdminDictPinOilImportFile}
          uploadFile={async (files: File[]) => {
            await queryAdminDictPinOilUploadFile({ body: { file: files[0] } });
            return undefined;
          }}
          downloadResult={queryAdminDictPinOilImportResult}
          exampleLink={getResourceUrl('/data/downloads/Нефть пин-код.xlsx')}
        />
      </Modal>
      {!!editModal && (
        <Modal isOpen={true} onClose={onCloseEditModal}>
          <PincodesOilFormArmModal data={editModal.row} mode={editModal.show} />
        </Modal>
      )}
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => {
  return filter;
};

export default PincodesOilArmPage;
