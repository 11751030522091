import { FilterT2Tag } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getLicenseAlcoholColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('common.labels.iin'),
    sortable: true,
  },
  {
    name: 'ovdCode',
    label: loc('ovd.code'),
    sortable: true,
  },
  {
    name: 'kogd',
    label: loc('ovd.kogd'),
    sortable: true,
  },
  {
    name: 'address',
    label: loc('ovd.address'),
    sortable: false,
  },
  {
    name: 'licenseNumber',
    label: loc('license.alcohol.license.number'),
    sortable: false,
  },
  {
    name: 'licenseDate',
    label: loc('license.alcohol.license.date'),
    sortable: false,
  },
  {
    name: 'messageType',
    label: loc('ovd.message_type'),
    sortable: false,
    getContent: (row) => (row.messageType ? loc(`ovd.messageType.${row.messageType}`) : ''),
  },
];

export const getLicenseAlcoholFilterTags = ({ locale: { loc } }: { locale: LocaleStore }): Array<FilterT2Tag> => [
  {
    name: 'tin',
    label: loc('common.labels.iin'),
  },
  {
    name: 'ovdCode',
    label: loc('ovd.code'),
  },
  {
    name: 'kogd',
    label: loc('ovd.kogd'),
  },
  {
    name: 'address',
    label: loc('ovd.address'),
  },
  {
    name: 'licenseNumber',
    label: loc('license.alcohol.license.number'),
  },
  {
    name: 'licenseDate',
    label: loc('license.alcohol.license.date'),
  },
  {
    name: 'messageType',
    label: loc('ovd.message_type'),
    getValue: (row) => (row.messageType ? loc(`ovd.messageType.${row.messageType}`) : ''),
  },
];
