import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './CheckboxMultiselect.module.scss';
import { Checkbox } from '../../controls/checkbox/Checkbox';
import { Field } from 'ui/common/form';
import { isEqual } from 'common/utils';
import { UITheme } from 'ui/common/types';

const defaultGetOptionValue = (opt: any) => opt.value;
const defaultGetOptionLabel = (opt: any) => opt.label;
const defaultGetOptionDisabled = (opt: any) => !!opt.disabled;

export type CheckboxMultiselectProps = {
  className?: string;
  name?: string;
  variant?: 'default' | 'compact' | 'compact-outlined';
  value: Array<string | number | boolean>;
  onChange?: (value: Array<any>, option?: any) => void;
  options: Array<any>;
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  getOptionDisabled?: (option: any) => boolean;
  checkOptionsChange?: boolean;
  updateValueOnOptionsChange?: boolean;
  rows?: number;
  allOption?: boolean;
  allLabel?: string;
  isDisabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  theme?: UITheme;
};

export const CheckboxMultiselect: React.FC<CheckboxMultiselectProps> = ({
  className,
  name,
  variant = 'default',
  value,
  options,
  getOptionValue = defaultGetOptionValue,
  getOptionLabel = defaultGetOptionLabel,
  getOptionDisabled = defaultGetOptionDisabled,
  rows,
  onChange,
  allOption,
  allLabel = 'Все',
  error,
  checkOptionsChange = false,
  updateValueOnOptionsChange = false,
  theme = 'T1',
  isDisabled,
  readOnly,
}) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const [optionsState, setOptionsState] = useState(options);

  const valueMap: { [key: string]: boolean } = useMemo(
    () => Object.fromEntries(value.map((item) => [String(item), true])),
    [value]
  );

  const allSelected = allOption
    ? optionsState.filter((opt) => !getOptionDisabled(opt)).every((opt) => valueMap[String(getOptionValue(opt))])
    : false;

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>, option: any) => {
    const newValueMap = { ...valueMap };
    newValueMap[String(getOptionValue(option))] = e.target.checked;
    const newValue = optionsState
      .filter((opt) => newValueMap[String(getOptionValue(opt))])
      .map((opt) => getOptionValue(opt));
    if (typeof onChangeRef.current === 'function') {
      onChangeRef.current(newValue, option);
    }
  };

  const onAllChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue: Array<any> = [];
    if (e.target.checked) {
      newValue = optionsState.filter((opt) => !getOptionDisabled(opt)).map((opt) => getOptionValue(opt));
    }
    if (typeof onChangeRef.current === 'function') {
      onChangeRef.current(newValue);
    }
  };

  useEffect(() => {
    // console.log('change options effect');
    if (checkOptionsChange && options !== optionsState) {
      // console.log('comparing');
      if (!isEqual(options, optionsState)) {
        // console.log('!isEqual');
        setOptionsState(options);
        if (updateValueOnOptionsChange) {
          // console.log('updateValueOnOptionsChange');
          const newValue: any[] = [];
          options.forEach((opt) => {
            if (!getOptionDisabled(opt) && valueMap[getOptionValue(opt)]) {
              newValue.push(getOptionValue(opt));
            }
          });
          if (typeof onChangeRef.current === 'function') {
            onChangeRef.current(newValue);
          }
        }
      }
    }
  }, [
    options,
    optionsState,
    valueMap,
    getOptionDisabled,
    getOptionValue,
    checkOptionsChange,
    updateValueOnOptionsChange,
  ]);

  // Число рядов по умолчанию для разных вариантов
  const gridRows = rows || (variant === 'default' ? 3 : undefined);

  return (
    <div
      className={clsx(className, s.wrapper, s[theme], {
        [s.error]: error,
        [s.compact]: variant === 'compact',
        [s.compactOutlined]: variant === 'compact-outlined',
      })}
      style={
        gridRows
          ? {
              gridTemplateRows: `repeat(${gridRows}, ${variant === 'default' ? '50px' : 'auto'})`,
              gridAutoFlow: 'column',
            }
          : {}
      }
    >
      {allOption && (
        <Field label={allLabel} direction="line-reverse" noMargin htmlFor={`chm_${name}_all`} theme={theme}>
          <Checkbox
            id={`chm_${name}_all`}
            checked={allSelected}
            onChange={(e) => onAllChange(e)}
            error={error}
            theme={theme}
            disabled={isDisabled || readOnly}
          />
        </Field>
      )}
      {optionsState.map((option) => (
        <Field
          key={getOptionValue(option)}
          label={getOptionLabel(option)}
          direction="line-reverse"
          noMargin
          htmlFor={`chm_${name}_${getOptionValue(option)}`}
          labelClassName={s.checkboxLabel}
          theme={theme}
          innerFullWidth={false}
        >
          <Checkbox
            id={`chm_${name}_${getOptionValue(option)}`}
            checked={valueMap[String(getOptionValue(option))] || false}
            onChange={(e) => onCheckboxChange(e, option)}
            error={error}
            disabled={getOptionDisabled(option) || isDisabled || readOnly}
            theme={theme}
          />
        </Field>
      ))}
    </div>
  );
};
