import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getPincodesAlcoholColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.alco_tin'),
    width: 350,
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.alco_productName'),
  },
  {
    name: 'productionType',
    label: loc('pincodes.table.title.productType'),
  },
  {
    name: 'spiritPercent',
    label: loc('pincodes.alcohol.table.title.spiritPercent'),
  },
  {
    name: 'tareCapacity',
    label: loc('pincodes.alcohol.table.title.tareCapacity'),
  },
];
