import { EDocumentStatus, USER_SERVICE_ROLE } from 'common/constants';
import { LocaleStore } from 'services/l10n/LocaleStore';

export const getNotSelectedOptions = ({ loc }: LocaleStore): Array<{ value: string; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
];

export const getOptionsUserServiceRole = ({ loc }: LocaleStore): Array<{ value: string; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: USER_SERVICE_ROLE.ADMIN,
    label: loc(`admin.user.role.ADMIN`),
  },
  {
    value: USER_SERVICE_ROLE.OGD_ADMIN,
    label: loc(`admin.user.role.OGD_ADMIN`),
  },
  {
    value: USER_SERVICE_ROLE.SUPPORT,
    label: loc(`admin.user.role.SUPPORT`),
  },
  {
    value: USER_SERVICE_ROLE.OGD,
    label: loc(`admin.user.role.OGD`),
  },
  {
    value: USER_SERVICE_ROLE.IS_USER,
    label: loc(`admin.user.role.IS_USER`),
  },
  {
    value: USER_SERVICE_ROLE.CA_USER,
    label: loc(`admin.user.role.CA_USER`),
  },
  {
    value: USER_SERVICE_ROLE.NSI_ADMIN,
    label: loc(`admin.user.role.NSI_ADMIN`),
  },
];

export const getOptionsYesNo = ({ loc }: LocaleStore, withEmpty = true): Array<{ value: any; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  {
    value: true,
    label: loc(`common.yes`),
  },
  {
    value: false,
    label: loc(`common.no`),
  },
];

export const getClosedOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: true,
    label: loc(`common.option.closed`),
  },
  {
    value: false,
    label: loc(`common.option.opened`),
  },
];

export const getNewsStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: 'DRAFT',
    label: loc('common.status.draft'),
  },
  {
    value: 'PUBLISHED',
    label: loc('common.status.published'),
  },
];

export const getEDocumentStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...Object.values(EDocumentStatus).map((status) => ({
    value: status,
    label: loc(`common.eDocumentStatus.${status}`),
  })),
];

export const getDirectionOptions = (
  { loc }: LocaleStore,
  withEmpty: boolean = true
): Array<{ value: any; label: string }> => {
  const options = [
    {
      value: 'OUTBOUND',
      label: loc(`direction.OUTBOUND`),
    },
    {
      value: 'INBOUND',
      label: loc(`direction.INBOUND`),
    },
    {
      value: 'IN_WORK',
      label: loc(`direction.IN_WORK`),
    },
  ];

  if (withEmpty) {
    options.unshift({
      value: '',
      label: loc('common.option.notSelected'),
    });
  }
  return options;
};


