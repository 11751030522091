import { LocaleStore } from 'services/l10n';

export const getOvdMessageTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: 'REGISTRATION',
    label: loc(`ovd.messageType.REGISTRATION`),
  },
  {
    value: 'DELETE',
    label: loc(`ovd.messageType.DELETE`),
  },
  {
    value: 'EDIT',
    label: loc(`ovd.messageType.EDIT`),
  },
];
