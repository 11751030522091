import { useLocale } from 'services/l10n';
import styles from './PincodesOilFormArmModal.module.scss';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Button, FormField, FormInput } from 'ui/common';
import { ModalContent, ModalHeader, ModalChildrenProps, ModalFooter } from 'services/modal';
import { solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { queryAdminDictPinOilSave } from '../../../api';
import { ToastService } from '../../../../../services/toasts';

type Props = ModalChildrenProps<boolean> & {
  data?: any;
  mode: 'view' | 'edit' | 'add';
};

export const PincodesOilFormArmModal: React.FC<Props> = ({ onClose, data, mode }) => {
  const locale = useLocale();
  const { loc } = locale;

  const initialValues = useMemo(() => {
    return {
      ...data,
    };
  }, [data]);

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    try {
      const res = await queryAdminDictPinOilSave({
        body: {
          pin: JSON.stringify(values),
        },
      });
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error: any) {
      ToastService.showError(solveErrorMessage(error));
      form.setErrors(solveFormErrors(error?.data?.data));
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <Formik validateOnChange={false} validateOnBlur={false} initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form className={clsx(styles.wrapper)}>
          <ModalHeader>{loc('pincodes.form.title')}</ModalHeader>
          <ModalContent className={styles.content}>
            <FormField
              name="tin"
              label={loc('pincodes.table.title.tin')}
              fast
              readOnly={mode === 'view' || mode === 'edit'}
            >
              <FormInput />
            </FormField>
            <FormField
              name="pinCode"
              label={loc('pincodes.table.title.pinCode')}
              fast
              readOnly={mode === 'view' || mode === 'edit'}
            >
              <FormInput />
            </FormField>
            <FormField
              name="productName"
              label={loc('pincodes.table.title.oil_productName')}
              fast
              readOnly={mode === 'view'}
            >
              <FormInput />
            </FormField>
            <FormField
              name="productionType"
              label={loc('pincodes.table.title.productType')}
              fast
              readOnly={mode === 'view'}
            >
              <FormInput />
            </FormField>
          </ModalContent>
          <ModalFooter>
            {mode === 'view' ? (
              <Button
                variant="transparent"
                size="small"
                onClick={() => typeof onClose === 'function' && onClose(false)}
              >
                {loc('common.button.close')}
              </Button>
            ) : (
              <>
                <Button type="submit" variant="primary" size="small" loading={isSubmitting}>
                  {loc('common.button.save')}
                </Button>
                <Button
                  variant="transparent"
                  size="small"
                  onClick={() => typeof onClose === 'function' && onClose(false)}
                >
                  {loc('common.button.cancel')}
                </Button>
              </>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
