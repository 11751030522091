import { FilterT2ContentProps } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FormFieldT2, FormInputT2, FormSelectT2 } from 'ui';
import { FormLocaleDatePickerT2 } from 'common/components/controls';
import { getOvdMessageTypeOptions } from 'pages/ovd/helpers';

type Props = FilterT2ContentProps;

export const LicenseAlcoholTaxpayerFiltersAdvanced: React.FC<Props> = ({ classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { advancedWrapper } = classNames || {};

  const messageTypeOptions = getOvdMessageTypeOptions(locale);

  return (
    <div className={advancedWrapper}>
      <FormFieldT2 name="tin" label={loc('common.labels.iin')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="ovdCode" label={loc('ovd.code')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="kogd" label={loc('ovd.kogd')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="address" label={loc('ovd.kogd')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="licenseNumber" label={loc('license.alcohol.license.number')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="licenseDate" label={loc('license.alcohol.license.date')}>
        <FormLocaleDatePickerT2 />
      </FormFieldT2>
      <FormFieldT2 name="messageType" label={loc('ovd.message_type')}>
        <FormSelectT2 options={messageTypeOptions} />
      </FormFieldT2>
    </div>
  );
};
