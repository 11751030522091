import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './OvdOilArmEditModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormField, FormInput, FormSelect } from 'ui';
import { mutationAdminDictionaryOvdOilSave } from '../../api/queriesOvdOilArm';
import { ToastService } from 'services/toasts';
import { solveFormErrors } from 'common/form/helpers';
import { getOvdMessageTypeOptions } from 'pages/ovd/helpers';

type Props = ModalChildrenProps<boolean> & {
  data?: any;
  mode: 'view' | 'edit' | 'add';
};

export const OvdOilArmEditModal: React.FC<Props> = ({ onClose, data, mode }) => {
  const locale = useLocale();
  const { loc } = locale;
  const messageTypeOptions = getOvdMessageTypeOptions(locale);
  const initialValues = useMemo(() => {
    return {
      ...data,
      messageType: 'REGISTRATION',
    };
  }, [data]);

  const sibmitHandler = async (values: any, form: FormikHelpers<any>) => {
    try {
      const res = await mutationAdminDictionaryOvdOilSave({
        body: values,
      });
      if (mode === 'add') {
        ToastService.showSuccess(loc('ovd.edit.save'));
      } else {
        ToastService.showSuccess(loc('ovd.edit.success'));
      }
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error: any) {
      if (error?.message) {
        ToastService.showError(error?.message);
      }
      form.setErrors(solveFormErrors(error?.data));
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <div className={clsx(s.wrapper)}>
      <ModalHeader>{loc('ovd.title.add')}</ModalHeader>
      <Formik initialValues={initialValues} onSubmit={sibmitHandler}>
        {({ isSubmitting }) => (
          <Form>
            <Fragment>
              <ModalContent className={s.content}>
                <FormField
                  readOnly={mode === 'view' || mode === 'edit'}
                  fast
                  name="tin"
                  label={loc('common.labels.iin')}
                >
                  <FormInput />
                </FormField>
                <FormField readOnly={mode === 'view' || mode === 'edit'} fast name="ovdCode" label={loc('ovd.code')}>
                  <FormInput />
                </FormField>
                <FormField readOnly={mode === 'view' || mode === 'edit'} fast name="kogd" label={loc('ovd.kogd')}>
                  <FormInput />
                </FormField>
                <FormField readOnly={mode === 'view'} name="address" label={loc('ovd.address')}>
                  <FormInput />
                </FormField>
                <FormField
                  readOnly={mode === 'view'}
                  name="petrolStationName"
                  label={loc('ovd.oil.petrol_station_name')}
                >
                  <FormInput />
                </FormField>
                <FormField readOnly={mode === 'view'} name="carNumber" label={loc('ovd.oil.car_number')}>
                  <FormInput />
                </FormField>
                <FormField readOnly={mode === 'view'} name="markOfCar" label={loc('ovd.oil.mark_of_car')}>
                  <FormInput />
                </FormField>
                <FormField readOnly={mode === 'view'} name="messageType" label={loc('ovd.message_type')}>
                  <FormSelect options={messageTypeOptions} />
                </FormField>
              </ModalContent>
              <ModalFooter>
                {mode === 'view' ? (
                  <Button
                    variant="transparent"
                    size="small"
                    onClick={() => typeof onClose === 'function' && onClose(false)}
                  >
                    {loc('common.button.close')}
                  </Button>
                ) : (
                  <>
                    <Button type="submit" variant="primary" size="small" loading={isSubmitting}>
                      {loc('common.button.save')}
                    </Button>
                    <Button
                      variant="transparent"
                      size="small"
                      onClick={() => typeof onClose === 'function' && onClose(false)}
                    >
                      {loc('common.button.cancel')}
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Fragment>
          </Form>
        )}
      </Formik>
    </div>
  );
};
