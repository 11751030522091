import { DataMutationFunction, DataQueryFunction, downloadFile, httpClient } from '../../../rest';
import { API_URL } from '../../../common/constants';
import { convertToFormData } from 'rest/helpers';
import { clearEmptyFields } from '../../../common/utils';

export const queryAdminDictPinAlcoholExport: DataQueryFunction = (variables: any) => {
  const { filterItems, pagination } = variables;

  return downloadFile({
    url: `${API_URL}/admin/dict/pin/alcohol/export`,
    method: 'GET',
    params: {
      filterItems,
      ...pagination,
    },
  });
};

export const queryAdminDictPinAlcoholImportFile: DataMutationFunction = async (): Promise<any> => {
  const res = await httpClient.post(`admin/dict/pin/alcohol/importFromFile`);

  if (!res?.data?.error && res?.status === 200) {
    return res;
  } else {
    throw res;
  }
};

export const queryAdminDictPinAlcoholUploadFile: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  const formData = convertToFormData(body);

  const res = await httpClient.post(`admin/dict/pin/alcohol/uploadFile`, formData);
  if (res?.status === 200) {
    return res;
  } else {
    throw res;
  }
};

export const queryAdminDictPinAlcoholSave: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  const formData = convertToFormData(clearEmptyFields(body));

  const res = await httpClient.post('admin/dict/pin/alcohol/save', formData);
  if (res?.data?.success === true) {
    return res;
  } else {
    throw res;
  }
};

export const queryAdminDictPinAlcoholImportResult: DataQueryFunction = () => {
  return downloadFile({
    url: `${API_URL}/admin/dict/pin/alcohol/getLastImportResult`,
    method: 'GET',
  });
};
