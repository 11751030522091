import { FilterContentProps } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect } from 'ui';
import { FormLocaleDatePicker } from 'common/components/controls';
import { getOvdMessageTypeOptions } from 'pages/ovd/helpers';

type Props = FilterContentProps;

export const LicenseAlcoholArmFiltersAdvanced: React.FC<Props> = ({ classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection, gridSectionExpandalbe } = classNames || {};

  const messageTypeOptions = getOvdMessageTypeOptions(locale);

  return (
    <div className={gridWrapper}>
      <div className={gridSection}>
        <FormField name="tin" label={loc('common.labels.iin')}>
          <FormInput />
        </FormField>
        <FormField name="ovdCode" label={loc('ovd.code')}>
          <FormInput />
        </FormField>
        <FormField name="kogd" label={loc('ovd.kogd')}>
          <FormInput />
        </FormField>
        <FormField name="address" label={loc('ovd.address')}>
          <FormInput />
        </FormField>
      </div>
      <div className={gridSectionExpandalbe}>
        <div className={gridSection}>
          <FormField name="licenseNumber" label={loc('license.alcohol.license.number')}>
            <FormInput />
          </FormField>
          <FormField name="licenseDate" label={loc('license.alcohol.license.date')}>
            <FormLocaleDatePicker />
          </FormField>
          <FormField name="messageType" label={loc('ovd.message_type')}>
            <FormSelect options={messageTypeOptions} />
          </FormField>
        </div>
      </div>
    </div>
  );
};
