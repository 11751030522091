export enum AwpStatus {
  /**
   * Черновик
   */
  DRAFT = 'DRAFT',

  /**
   * Не просмотрен
   */
  NOT_VIEWED = 'NOT_VIEWED',

  /**
   * Доставлен
   */
  DELIVERED = 'DELIVERED',

  /**
   * Созданный
   */
  CREATED = 'CREATED',

  /**
   * Черновик импортирован
   */
  IMPORTED = 'IMPORTED',

  /**
   * Ошибочный
   */
  FAILED = 'FAILED',

  /**
   * Подтвержден
   */
  CONFIRMED = 'CONFIRMED',

  /**
   * Отклонен
   */
  DECLINED = 'DECLINED',

  /**
   * Отозван
   */
  REVOKED = 'REVOKED',

  /**
   * В процессе расторжения
   */
  IN_TERMINATING = 'IN_TERMINATING',

  /**
   * Расторгнут
   */
  TERMINATED = 'TERMINATED',

  /**
   * Аннулирован
   */
  CANCELED = 'CANCELED',
}

export enum AwpRecipientStatus {
  WAIT_CONFIRMATION_TERMINATION = 'WAIT_CONFIRMATION_TERMINATION',
}

export enum AwpMode {
  new = 'new',
  show = 'show',
  copy = 'copy',
  fixed = 'fixed',
}

export enum AwpType {
  PRIMARY = 'PRIMARY',
  FIXED = 'FIXED',
}

export const AWP_STATUS_CLASSES: { [key in AwpStatus]?: string } = {
  [AwpStatus.DRAFT]: 'statusColorGray',
  [AwpStatus.NOT_VIEWED]: 'statusColorYellow',
  [AwpStatus.DELIVERED]: 'statusColorYellow',
  [AwpStatus.CREATED]: 'statusColorYellow',
  [AwpStatus.IMPORTED]: 'statusColorGray',
  [AwpStatus.FAILED]: 'statusColorRed',
  [AwpStatus.CONFIRMED]: 'statusColorGreen',
  [AwpStatus.DECLINED]: 'statusColorRed',
  [AwpStatus.REVOKED]: 'statusColorBlack',
  [AwpStatus.IN_TERMINATING]: 'statusColorBlack',
  [AwpStatus.TERMINATED]: 'statusColorBlack',
  [AwpStatus.CANCELED]: 'statusColorBlack',
};

export const AWP_FILTER_OUTBOUND_STATUSES = [
  AwpStatus.NOT_VIEWED,
  AwpStatus.DELIVERED,
  AwpStatus.CREATED,
  AwpStatus.CONFIRMED,
  AwpStatus.DECLINED,
  AwpStatus.REVOKED,
  AwpStatus.IN_TERMINATING,
  AwpStatus.TERMINATED,
  AwpStatus.CANCELED,
];

export const AWP_FILTER_IN_WORK_STATUSES = [AwpStatus.DRAFT, AwpStatus.IMPORTED, AwpStatus.FAILED];

export enum AwpTxpStatus {
  /**
   * Юридическое лицо
   */
  ENTERPRISE = 'ENTERPRISE',

  /**
   * Индивидуальный предприниматель
   */
  ENTREPRENEUR = 'ENTREPRENEUR',

  /**
   * Физическое лицо
   */
  INDIVIDUAL = 'INDIVIDUAL',

  /**
   * Адвокат
   */
  LAWYER = 'LAWYER',

  /**
   * Нотариус
   */
  NOTARY = 'NOTARY',

  /**
   * Медиатор
   */
  MEDIATOR = 'MEDIATOR',

  /**
   * Частный судебный исполнитель
   */
  BAILIFF = 'BAILIFF',

  /**
   * Участник договора совместной деятельности
   */
  JOINT_ACTIVITY_PARTICIPANT = 'JOINT_ACTIVITY_PARTICIPANT',

  /**
   * Участник СРП или сделки, заключенной в рамках СРП
   */
  SHARING_AGREEMENT_PARTICIPANT = 'SHARING_AGREEMENT_PARTICIPANT',
}
