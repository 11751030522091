import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getPincodesTobaccoColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.tobacco_tin'),
    width: 350,
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.tobacco_productName'),
    width: 350,
  },
  {
    name: 'mark',
    label: loc('pincodes.tobacco.table.title.mark'),
  },
  {
    name: 'quantityInPack',
    label: loc('pincodes.tobacco.table.title.quantityInPack'),
  },
];
