import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './LicenseAlcoholArmEditModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormField, FormInput, FormSelect } from 'ui';
import { mutationAdminDictionaryAlcoholSave } from '../../api/queriesLicenseAlcoholArm';
import { ToastService } from 'services/toasts';
import { solveFormErrors } from 'common/form/helpers';
import { FormLocaleDatePicker } from 'common/components/controls/FormLocaleDatePicker';
import { getOvdMessageTypeOptions } from 'pages/ovd/helpers';

type Props = ModalChildrenProps<boolean> & {
  data?: any;
  mode: 'view' | 'edit' | 'add';
};

export const LicenseAlcoholArmEditModal: React.FC<Props> = ({ onClose, data, mode }) => {
  const locale = useLocale();
  const { loc } = locale;
  const messageTypeOptions = getOvdMessageTypeOptions(locale);

  const initialValues = useMemo(() => {
    return {
      ...data,
      messageType: 'REGISTRATION',
    };
  }, [data]);

  const submitHandler = async (values: any, form: FormikHelpers<any>) => {
    try {
      const res = await mutationAdminDictionaryAlcoholSave({
        body: values,
      });
      if (mode === 'add') {
        ToastService.showSuccess(loc('license.alcohol.edit.save'));
      } else {
        ToastService.showSuccess(loc('license.alcohol.edit.success'));
      }
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error: any) {
      if (error?.message) {
        ToastService.showError(error?.message);
      }
      form.setErrors(solveFormErrors(error?.data));
    } finally {
      form.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      {({ isSubmitting }) => (
        <Form className={clsx(s.wrapper)}>
          <ModalHeader>{loc('license.alcohol.add')}</ModalHeader>
          <ModalContent className={s.content}>
            <FormField readOnly={mode === 'view' || mode === 'edit'} fast name="tin" label={loc('common.labels.iin')}>
              <FormInput />
            </FormField>
            <FormField readOnly={mode === 'view' || mode === 'edit'} fast name="ovdCode" label={loc('ovd.code')}>
              <FormInput />
            </FormField>
            <FormField readOnly={mode === 'view' || mode === 'edit'} fast name="kogd" label={loc('ovd.kogd')}>
              <FormInput />
            </FormField>
            <FormField readOnly={mode === 'view'} fast name="address" label={loc('ovd.address')}>
              <FormInput />
            </FormField>
            <FormField
              readOnly={mode === 'view' || mode === 'edit'}
              fast
              name="licenseNumber"
              label={loc('license.alcohol.license.number')}
            >
              <FormInput />
            </FormField>
            <FormField
              readOnly={mode === 'view' || mode === 'edit'}
              fast
              name="licenseDate"
              label={loc('license.alcohol.license.date')}
            >
              <FormLocaleDatePicker />
            </FormField>
            <FormField readOnly={mode === 'view'} fast name="messageType" label={loc('ovd.message_type')}>
              <FormSelect options={messageTypeOptions} />
            </FormField>
          </ModalContent>
          <ModalFooter>
            {mode === 'view' ? (
              <Button
                variant="transparent"
                size="small"
                onClick={() => typeof onClose === 'function' && onClose(false)}
              >
                {loc('common.button.close')}
              </Button>
            ) : (
              <>
                <Button type="submit" variant="primary" size="small" loading={isSubmitting}>
                  {loc('common.button.save')}
                </Button>
                <Button
                  variant="transparent"
                  size="small"
                  onClick={() => typeof onClose === 'function' && onClose(false)}
                >
                  {loc('common.button.cancel')}
                </Button>
              </>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
