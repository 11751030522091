import { FilterT2Tag } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getPincodesTobaccoColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.tobacco_tin'),
    sortable: true,
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
    sortable: true,
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.tobacco_productName'),
    sortable: true,
  },
  {
    name: 'mark',
    label: loc('pincodes.tobacco.table.title.mark'),
    sortable: false,
  },
  {
    name: 'quantityInPack',
    label: loc('pincodes.tobacco.table.title.quantityInPack'),
    sortable: false,
  },
];

export const getPincodesTobaccoFilterTags = ({ locale: { loc } }: { locale: LocaleStore }): Array<FilterT2Tag> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.tobacco_tin'),
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.tobacco_productName'),
  },
  {
    name: 'mark',
    label: loc('pincodes.tobacco.table.title.mark'),
  },
  {
    name: 'quantityInPack',
    label: loc('pincodes.tobacco.table.title.quantityInPack'),
  },
];
