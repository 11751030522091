import { OgdPage } from 'pages/arm/ogd';
import { ExternalUsersPage } from 'pages/arm/user-actions/ExternalUsersPage';
import { InternalUsersPage } from 'pages/arm/user-actions/InternalUsersPage';
import ArmUsersPage from 'pages/arm/users/ArmUsersPage';
import { SntExportReportPage } from 'pages/export-control/snt-export-report/SntExportReportPage';
import React from 'react';
import { PATH } from '../paths';
import { LAYOUT } from '../layouts';
import { RootRouteConfig } from '../types';
import { UserDetailsPage } from 'pages/arm/ures-details/UserDetailsPage';
import { VirtualLabelChainPage } from 'pages/arm/virtual-label-chain/VirtualLabelChainPage';
import { StatisticReportPage } from 'pages/arm/statistic-report/StatisticReportPage';
import { SaldoReportPageArm } from 'pages/saldo-report/SaldoReportPageArm';
import { SaldoReportPageTaxpayer } from 'pages/saldo-report/SaldoReportPageTaxpayer';
import { PassportsJournalArm } from 'pages/assurance/passports-journal/PassportsJournalArm';
import { EaecCodeGeneration } from 'pages/eaec/code-generation/EaecCodeGeneration';
import { DevPage } from 'pages/common/login/DevPage';
import { PassportsJournalTaxpayer } from 'pages/assurance/passports-journal/PassportsJournalTaxpayer';
import { DocumentLinkPage } from 'common/components/links/DocumentLinkPage';
import { KkmStub } from 'pages/kkm/kkm-stub/KkmStub';
import { PrintJournalPage } from 'pages/print/PrintJournalPage';
import { SmallRetailDictionary } from 'pages/small-retail/small-retail-dictionary/SmallRetailDictionary';
import { SmallRetailTaxpayerPage } from 'pages/small-retail/small-retail-taxpayer/SmallRetailTaxpayerPage';
import { ZvtJournalArmPage } from 'pages/zvt/zvt-journal-arm/ZvtJournalArmPage';
import { ZvtJournalTxpPage } from 'pages/zvt/zvt-journal-taxpayer/ZvtJournalTxp';
import { SendAdminMessagePage } from 'pages/admin-messages';
import { AdminMessageJournal } from 'pages/admin-messages/admin-message-journal/AdminMessageJournal';
import { SntTermsConfirmation } from 'pages/snt-terms/snt-terms-confirmation/SntTermsConfirmation';
import { SntTermsRegistration } from 'pages/snt-terms/snt-terms-registration/SntTermsRegistration';
import { SettingsVstore } from 'pages/settings-vstore/SettingsVstore';
import { EaecDocumentsInput } from 'pages/eaec-actions/eaec-documents-input/EaecDocumentsInput';
import { EaecDocumentsOutput } from 'pages/eaec-actions/eaec-documents-output/EaecDocumentsOutput';
import { EaecOperationsInput } from 'pages/eaec-actions/eaec-operations-input/EaecOperationsInput';
import { EaecOperationsOutput } from 'pages/eaec-actions/eaec-operations-output/EaecOperationsOutput';
import { EaecMonitoringJournal } from 'pages/eaec-actions/eaec-monitoring-journal/EaecMonitoringJournal';
import { QoldayRegistryJournal } from 'pages/qoo/qolday-registry-journal/QoldayRegistryJournal';
import { NotBioTaxpayersJournal } from 'pages/arm/not-bio/not-bio-journal/NotBioTaxpayersJournal';
import { RemainderJournalArm } from 'pages/remainder-goods/remainder-goods-arm/RemainderJournalArm';
import { BioRiskyJournal } from 'pages/arm/bio-risky-taxpayers/bio-risky-journal/BioRiskyJournal';
import { TaxpayerMainPage } from 'pages/main-taxpayer/TaxpayerMainPage';
import { MessagesPage } from 'pages/messages';
import { GovInstJournal } from 'pages/arm/gov-inst/gov-inst-journal/GovInstJournal';
import { EaecTransactionJournal } from 'pages/eaec-actions/eaec-transaction/EaecTransactionJournal';
import { LocaleNames } from 'services/l10n';
import { DocTransferJournalTxp } from 'pages/doc-transfer/doc-transfer-journal-taxpayer/DocTransferJournalTxp';
import { DocTransferJournalArm } from 'pages/doc-transfer/doc-transfer-journal-arm/DocTransferJournalArm';
import { BigcompaniesJournal } from 'pages/arm/bigcompanies/BigcompaniesJournal';
import PincodesOilTaxpayerPage from '../../pages/pincodes/pincodes-oil-taxpayer/PincodesOilTaxpayerPage';
import PincodesTobaccoTaxpayerPage from '../../pages/pincodes/pincodes-tobacco-taxpayer/PincodesTobaccoTaxpayerPage';
import PincodesAlcoholTaxpayerPage from '../../pages/pincodes/pincodes-alcohol-taxpayer/PincodesAlcoholTaxpayerPage';
import PincodesOilArmPage from '../../pages/pincodes/pincodes-oil-arm/PincodesOilArmPage';
import PincodesAlcoholArmPage from '../../pages/pincodes/pincodes-alcohol-arm/PincodesAlcoholArmPage';
import PincodesTobaccoArmPage from '../../pages/pincodes/pincodes-tobacco-arm/PincodesTobaccoArmPage';
import { ObjectsOvdOilTaxpayerPage } from 'pages/ovd/ovd-oil-taxpayer/OvdOilTaxpayer';
import { ObjectsOvdTobaccoTaxpayerPage } from 'pages/ovd/ovd-tobacco-taxpayer/OvdTobaccoTaxpayer';
import { LicenseAlcoholTaxpayerPage } from 'pages/ovd/license-alcohol/license-alcohol-taxpayer/LicenseAlcoholTaxpayer';
import { OvdOilArmPage } from 'pages/ovd/ovd-oil-arm/OvdOilArm';
import { OvdTobaccoArmPage } from 'pages/ovd/ovd-tobacco-arm/OvdTobaccoArm';
import { LicenseAlcoholArmPage } from 'pages/ovd/license-alcohol/license-alcohol-arm/LicenseAlcoholArm';

export const routesProtectedConfig: RootRouteConfig = {
  layout: LAYOUT.EMPTY,
  routes: [
    {
      path: PATH.PRINT_JOURNAL,
      component: PrintJournalPage,
    },
    // Рауты для кабинета налогоплательщика
    {
      path: '',
      layout: LAYOUT.TAXPAYER,
      children: [
        {
          path: PATH.MAIN,
          exact: true,
          component: TaxpayerMainPage,
        },
        {
          path: PATH.ASSURANCE_JOURNAL,
          component: PassportsJournalTaxpayer,
          exact: true,
        },
        {
          path: PATH.SALDO_REPORT,
          component: SaldoReportPageTaxpayer,
        },
        {
          path: PATH.KKM_JOURNAL,
          component: KkmStub,
        },
        {
          path: PATH.KKM_WRITEOFF_JOURNAL,
          component: KkmStub,
        },
        {
          path: PATH.ESF_BLANK,
          component: React.lazy(() => import('pages/esf/esf-page/EsfPage')),
          locales: [LocaleNames.ESF],
        },
        {
          path: PATH.ESF_BLANK_NEW,
          component: React.lazy(() => import('pages/esf/esf-page/EsfPage')),
          locales: [LocaleNames.ESF],
        },
        {
          path: PATH.ESF_JOURNAL,
          component: React.lazy(() => import('pages/esf/esf-journal/EsfJournalPage')),
          exact: true,
          locales: [LocaleNames.ESF],
        },
        {
          path: PATH.SNT_JOURNAL,
          component: React.lazy(() => import('pages/snt/snt-journal/SntJournalPage')),
          exact: true,
          locales: [LocaleNames.SNT],
        },
        {
          path: PATH.SNT_FORM,
          component: React.lazy(() => import('pages/snt/snt-page/SntPage')),
          locales: [LocaleNames.SNT],
        },
        {
          path: PATH.SNT_FORM_NEW,
          component: React.lazy(() => import('pages/snt/snt-page/SntPage')),
          locales: [LocaleNames.SNT],
        },
        {
          path: PATH.SMALL_RETAIL,
          component: SmallRetailTaxpayerPage,
        },
        {
          path: PATH.ZVT_JOURNAL,
          component: ZvtJournalTxpPage,
          exact: true,
        },
        {
          path: PATH.SETTINGS_VS,
          component: SettingsVstore,
        },
        {
          path: PATH.MESSAGES,
          component: MessagesPage,
        },
        {
          path: PATH.DOC_TRANSFER,
          component: DocTransferJournalTxp,
        },
        {
          path: PATH.PINCODES_OIL,
          component: PincodesOilTaxpayerPage,
        },
        {
          path: PATH.PINCODES_TOBACCO,
          component: PincodesTobaccoTaxpayerPage,
        },
        {
          path: PATH.PINCODES_ALCOHOL,
          component: PincodesAlcoholTaxpayerPage,
        },
        {
          path: PATH.OVD_OIL,
          component: ObjectsOvdOilTaxpayerPage,
        },
        {
          path: PATH.OVD_TOBACCO,
          component: ObjectsOvdTobaccoTaxpayerPage,
        },
        {
          path: PATH.LICENSE_ALCOHOL,
          component: LicenseAlcoholTaxpayerPage,
        },
      ],
    },

    // Рауты для кабинета АРМ
    {
      path: PATH.ARM,
      layout: LAYOUT.ARM,
      children: [
        {
          path: '',
          component: UserDetailsPage,
          exact: true,
        },
        {
          path: PATH.OGD,
          component: OgdPage,
        },
        {
          path: PATH.ARM_USERS,
          component: ArmUsersPage,
        },
        {
          path: PATH.NEWS,
          component: React.lazy(() => import('pages/arm/news/NewsPage')),
        },
        {
          path: PATH.REMAINDER,
          component: RemainderJournalArm,
        },
        {
          path: PATH.INTERNAL_USERS,
          component: InternalUsersPage,
        },
        {
          path: PATH.EXTERNAL_USERS,
          component: ExternalUsersPage,
        },
        {
          path: PATH.SNT_BORDER_CROSSING_REPORT,
          component: SntExportReportPage,
        },
        {
          path: PATH.VIRTUAL_LABEL_CHAIN,
          component: VirtualLabelChainPage,
        },
        {
          path: PATH.STATISTIC_DOCFLOW_REPORT,
          component: StatisticReportPage,
        },
        {
          path: PATH.SALDO_REPORT,
          component: SaldoReportPageArm,
        },
        {
          path: PATH.ASSURANCE_JOURNAL,
          component: PassportsJournalArm,
          exact: true,
        },
        {
          path: PATH.EAEC_CODE_GENERATION,
          component: EaecCodeGeneration,
        },
        {
          path: PATH.SMALL_RETAIL,
          component: SmallRetailDictionary,
        },
        {
          path: PATH.KKM_JOURNAL,
          component: KkmStub,
        },
        {
          path: PATH.KKM_WRITEOFF_JOURNAL,
          component: KkmStub,
        },
        {
          path: PATH.SEND_MESSAGE,
          component: SendAdminMessagePage,
        },
        {
          path: PATH.MESSAGES_JOURNAL,
          component: AdminMessageJournal,
        },
        {
          path: PATH.SNT_TERMS_REGISTRATION,
          component: SntTermsRegistration,
        },
        {
          path: PATH.SNT_TERMS_CONFIRMATION,
          component: SntTermsConfirmation,
        },
        {
          path: PATH.ZVT_JOURNAL,
          component: ZvtJournalArmPage,
        },
        {
          path: PATH.EAEC_DOCUMENTS_INPUT,
          component: EaecDocumentsInput,
        },
        {
          path: PATH.EAEC_DOCUMENTS_OUTPUT,
          component: EaecDocumentsOutput,
        },
        {
          path: PATH.EAEC_OPERATIONS_INPUT,
          component: EaecOperationsInput,
        },
        {
          path: PATH.EAEC_OPERATIONS_OUTPUT,
          component: EaecOperationsOutput,
        },
        {
          path: PATH.EAEC_MONITORING,
          component: EaecMonitoringJournal,
        },
        {
          path: PATH.EAEC_TRANSACTION,
          component: EaecTransactionJournal,
        },
        {
          path: PATH.QOLDAY_REGISTRY,
          component: QoldayRegistryJournal,
        },
        {
          path: PATH.NOT_BIO_TAXPAYERS,
          component: NotBioTaxpayersJournal,
        },
        {
          path: PATH.BIO_RISKY,
          component: BioRiskyJournal,
        },
        {
          path: PATH.GOV_INST,
          component: GovInstJournal,
        },
        {
          path: PATH.DOC_TRANSFER,
          component: DocTransferJournalArm,
        },
        {
          path: PATH.BIG_COMPANIES,
          component: BigcompaniesJournal,
        },
        {
          path: PATH.OVD_OIL,
          component: OvdOilArmPage,
        },
        {
          path: PATH.OVD_TOBACCO,
          component: OvdTobaccoArmPage,
        },
        {
          path: PATH.LICENSE_ALCOHOL,
          component: LicenseAlcoholArmPage,
        },
        {
          path: PATH.PINCODES_OIL,
          component: PincodesOilArmPage,
        },
        {
          path: PATH.PINCODES_ALCOHOL,
          component: PincodesAlcoholArmPage,
        },
        {
          path: PATH.PINCODES_TOBACCO,
          component: PincodesTobaccoArmPage,
        },
      ],
    },

    {
      path: PATH.DOCUMENT_LINK,
      component: DocumentLinkPage,
      layout: LAYOUT.EMPTY,
    },
    {
      path: PATH.DEV,
      component: DevPage,
      exclude: process.env.NODE_ENV === 'production',
      layout: LAYOUT.ARM,
    },
    {
      path: '*',
      redirect: PATH.MAIN,
    },
  ],
};
