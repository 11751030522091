import { ADMIN_PERMISSION } from 'common/constants';
import { UserStore } from 'common/stores/user-store/UserStore';
import { PATH } from 'routing/paths';
import { LocaleStore } from 'services/l10n/LocaleStore';
import { MenuItem } from 'ui';
import { getArmLegasyUrl } from '../getLegasyUrl';

export const adminMenu = ({ loc }: LocaleStore, user: UserStore): Array<MenuItem> => [
  {
    id: 0,
    label: loc('admin.menu.admin_credentials'),
    iconName: 'detail',
    link: `${PATH.ARM}`,
    disabled: !user.isAuthed,
  },
  {
    id: 1,
    label: loc('admin.menu.ADMINISTRATION'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 11,
        label: loc('admin.menu.users'),
        link: `${PATH.ARM}${PATH.ARM_USERS}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.USERS_MANAGE),
      },
    ],
  },

  {
    id: 2,
    label: loc('admin.menu.MONITORING_IS'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 201,
        label: loc('admin.menu.MESSAGES_SEND'),
        link: `${PATH.ARM}${PATH.SEND_MESSAGE}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.MESSAGES_SEND),
      },
      {
        id: 2011,
        label: loc('admin.menu.MESSAGES'),
        link: `${PATH.ARM}${PATH.MESSAGES_JOURNAL}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.MESSAGES_VIEW),
      },
      {
        id: 202,
        label: loc('admin.menu.NOTIFICATION_TEMPLATES_EDIT'),
        link: getArmLegasyUrl('NOTIFICATION_TEMPLATES_EDIT'),
        externalLink: true,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.NOTIFICATION_TEMPLATES_EDIT),
      },
      {
        id: 203,
        label: loc('admin.menu.SOFT_MANAGE'),
        link: getArmLegasyUrl('SOFT_MANAGE'),
        externalLink: true,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.SOFT_MANAGE),
      },
      {
        id: 204,
        label: loc('admin.menu.MONITORING'),
        link: getArmLegasyUrl('MONITORING'),
        externalLink: true,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.MONITORING),
      },
      {
        id: 205,
        label: loc('admin.menu.STATISTICS_VIEW'),
        link: getArmLegasyUrl('STATISTICS_VIEW'),
        externalLink: true,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.STATISTICS_VIEW),
      },
      {
        id: 206,
        label: loc('admin.menu.USER_ACCESS_EXTERNAL_VIEW'),
        link: `${PATH.ARM}${PATH.EXTERNAL_USERS}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.USER_ACCESS_EXTERNAL_VIEW),
      },
      {
        id: 207,
        label: loc('admin.menu.USER_ACCESS_INTERNAL_VIEW'),
        link: `${PATH.ARM}${PATH.INTERNAL_USERS}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.USER_ACCESS_INTERNAL_VIEW),
      },
      {
        id: 208,
        label: loc('admin.menu.DOWNTIME'),
        link: getArmLegasyUrl('DOWNTIME'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.DOWNTIME_VIEW, ADMIN_PERMISSION.DOWNTIME_EDIT]),
      },
      {
        id: 209,
        label: loc('admin.menu.NEWS'),
        link: `${PATH.ARM}${PATH.NEWS}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.NEWS_PUBLISH),
      },
      {
        id: 210,
        label: loc('admin.menu.CALENDAR'),
        link: getArmLegasyUrl('CALENDAR'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.CALENDAR_EDIT, ADMIN_PERMISSION.CALENDAR_VIEW]),
      },
    ],
  },
  {
    id: 3,
    label: loc('admin.menu.REGISTRATION'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 301,
        label: loc('admin.menu.TAXPAYER_SEARCH'),
        link: getArmLegasyUrl('TAXPAYER_SEARCH'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.TAXPAYER_SEARCH]),
      },
      {
        id: 302,
        label: loc('admin.menu.PERMISSION'),
        link: getArmLegasyUrl('PERMISSION'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.PERMISSION_VIEW]),
      },
      {
        id: 303,
        label: loc('admin.menu.AGREEMENT_ACTIONS'),
        link: getArmLegasyUrl('AGREEMENT_ACTIONS'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.AGREEMENT_ACTIONS]),
      },
      {
        id: 307,
        label: loc('admin.menu.MPT'),
        link: getArmLegasyUrl('MPT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.MPT_VIEW]),
      },
      {
        id: 304,
        label: loc('admin.menu.REREGISTRATION'),
        link: getArmLegasyUrl('REREGISTRATION'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.REREGISTRATION_VIEW]),
      },
      {
        id: 305,
        label: loc('admin.menu.CHECK_ACTIVE_REGISTRATION'),
        link: getArmLegasyUrl('CHECK_ACTIVE_REGISTRATION'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.CHECK_ACTIVE_REGISTRATION]),
      },
      {
        id: 306,
        label: loc('admin.menu.TAXPAYER_BLOCK'),
        link: getArmLegasyUrl('TAXPAYER_BLOCK'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.TAXPAYER_BLOCK_EDIT,
          ADMIN_PERMISSION.TAXPAYER_BLOCK_VIEW,
        ]),
      },
    ],
  },
  {
    id: 4,
    label: loc('admin.menu.VIRTUAL_STORE'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 401,
        label: loc('admin.menu.VIRTUAL_LABEL'),
        link: getArmLegasyUrl('VIRTUAL_LABEL'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.VIRTUAL_LABEL_VIEW]),
      },
      {
        id: 402,
        label: loc('admin.menu.TAXPAYER_STORE'),
        link: getArmLegasyUrl('TAXPAYER_STORE'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.TAXPAYER_STORE_VIEW]),
      },
      {
        id: 403,
        label: loc('admin.menu.FNO'),
        link: getArmLegasyUrl('FNO'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.FNO_VIEW]),
      },
      {
        id: 4031,
        label: loc('common.menu.ZVT'),
        link: `${PATH.ARM}${PATH.ZVT_JOURNAL}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.DOCUMENT_ZVT_VIEW]),
      },
      {
        id: 404,
        label: loc('admin.menu.GTD'),
        link: getArmLegasyUrl('GTD'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.GTD_VIEW]),
      },
      {
        id: 405,
        label: loc('admin.menu.UFORMS'),
        link: getArmLegasyUrl('UFORMS'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.UFORM_VIEW]),
      },
      {
        id: 406,
        label: loc('admin.menu.REMAINS'),
        link: `${PATH.ARM}${PATH.REMAINDER}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.VIEW_VIRTUAL_LABEL_REMAINDERS]),
      },
      {
        id: 407,
        label: loc('admin.menu.CERTIFICATE_VIEW'),
        link: getArmLegasyUrl('CERTIFICATE_VIEW'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.CERTIFICATE_VIEW]),
      },
      {
        id: 408,
        label: loc('admin.menu.VIRTUAL_LABEL_CHAIN'),
        link: `${PATH.ARM}${PATH.VIRTUAL_LABEL_CHAIN}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.VIRTUAL_LABEL_CHAIN_VIEW]),
      },
      {
        id: 409,
        label: loc('admin.menu.KKM'),
        link: `${PATH.ARM}${PATH.KKM_JOURNAL}`,
        disabled: !user.isAuthed,
      },
      {
        id: 410,
        label: loc('admin.menu.KKM_WRITEOFF'),
        link: `${PATH.ARM}${PATH.KKM_WRITEOFF_JOURNAL}`,
        disabled: !user.isAuthed,
      },
    ],
  },
  {
    id: 19,
    label: loc('admin.menu.ASSURANCE'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 1901,
        label: loc('admin.menu.PASSPORTS'),
        link: `${PATH.ARM}${PATH.ASSURANCE_JOURNAL}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.ASSURANCE_VIEW, ADMIN_PERMISSION.ASSURANCE_ACTIONS]),
      },
      {
        id: 1902,
        label: loc('admin.menu.CODE_GENERATION'),
        link: `${PATH.ARM}${PATH.EAEC_CODE_GENERATION}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EAEC_USERS_MANAGE]),
      },
    ],
  },
  {
    id: 5,
    label: loc('admin.menu.INVOICE'),
    iconName: 'detail',
    link: getArmLegasyUrl('INVOICE'),
    externalLink: true,
    disabled: !user.checkAdminPermission([ADMIN_PERMISSION.INVOICE_VIEW]),
  },
  {
    id: 6,
    label: loc('admin.menu.AWP'),
    iconName: 'detail',
    link: getArmLegasyUrl('AWP'),
    externalLink: true,
    disabled: !user.checkAdminPermission([ADMIN_PERMISSION.AWP_VIEW]),
  },
  {
    id: 7,
    label: loc('admin.menu.ECONTRACT'),
    iconName: 'detail',
    link: getArmLegasyUrl('ECONTRACT'),
    externalLink: true,
    disabled: !user.checkAdminPermission([ADMIN_PERMISSION.ECONTRACT_VIEW]),
  },
  {
    id: 8,
    label: loc('admin.menu.SNT'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 801,
        label: loc('admin.menu.SNT'),
        link: getArmLegasyUrl('SNT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.SNT_VIEW]),
      },
      {
        id: 802,
        label: loc('admin.menu.SNT_TERMS_REGISTRATION'),
        link: `${PATH.ARM}${PATH.SNT_TERMS_REGISTRATION}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.SNT_EXPIRE_CONTROL]),
      },
      {
        id: 803,
        label: loc('admin.menu.SNT_TERMS_CONFIRMATION'),
        link: `${PATH.ARM}${PATH.SNT_TERMS_CONFIRMATION}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.SNT_EXPIRE_CONTROL]),
      },
    ],
  },
  {
    id: 9,
    label: loc('admin.menu.EXPORT_CONTROL'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 901,
        label: loc('admin.menu.TRANSIT_REGISTRY'),
        link: getArmLegasyUrl('TRANSIT_REGISTRY'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.TRANSIT_REGISTRY_EDIT,
          ADMIN_PERMISSION.TRANSIT_REGISTRY_VIEW,
        ]),
      },
      {
        id: 902,
        label: loc('admin.menu.PERMISSIVE_DOCUMENT'),
        link: getArmLegasyUrl('PERMISSIVE_DOCUMENT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.PERMISSIVE_DOCUMENT_VIEW]),
      },
      {
        id: 903,
        label: loc('admin.menu.SNT_EAES'),
        link: getArmLegasyUrl('SNT_EAES'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.SNT_EAES_VIEW]),
      },
    ],
  },
  {
    id: 'docTransfer',
    label: loc('common.menu.docTransfer'),
    iconName: 'detail',
    link: `${PATH.ARM}${PATH.DOC_TRANSFER}`,
    disabled: false,
  },
  {
    id: 10,
    label: loc('admin.menu.DICTIONARY'),
    iconName: 'detail',
    childrenDirUp: true,
    childrenItems: [
      {
        id: 1001,
        label: loc('admin.menu.BANK'),
        link: getArmLegasyUrl('BANK'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.BANK_VIEW]),
      },
      {
        id: 1002,
        label: loc('admin.menu.BIG_COMPANY'),
        link: `${PATH.ARM}${PATH.BIG_COMPANIES}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.BIG_COMPANY_EDIT, ADMIN_PERMISSION.BIG_COMPANY_VIEW]),
      },
      {
        id: 1003,
        label: loc('admin.menu.CURRENCY_RATE'),
        link: getArmLegasyUrl('CURRENCY_RATE'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.CURRENCY_RATE_EDIT,
          ADMIN_PERMISSION.CURRENCY_RATE_VIEW,
        ]),
      },
      {
        id: 1004,
        label: loc('admin.menu.MEASURE_UNIT'),
        link: getArmLegasyUrl('MEASURE_UNIT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.MEASURE_UNIT_EDIT, ADMIN_PERMISSION.MEASURE_UNIT_VIEW]),
      },
      {
        id: 1005,
        label: loc('admin.menu.SHARING_PARTICIPANT'),
        link: getArmLegasyUrl('SHARING_PARTICIPANT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.SHARING_PARTICIPANT_EDIT,
          ADMIN_PERMISSION.SHARING_PARTICIPANT_VIEW,
        ]),
      },
      {
        id: 1006,
        label: loc('admin.menu.GSVS'),
        link: getArmLegasyUrl('GSVS'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.GSVS_EDIT, ADMIN_PERMISSION.GSVS_VIEW]),
      },
      {
        id: 1007,
        label: loc('admin.menu.unreliableAuto'),
        link: getArmLegasyUrl('unreliableAuto'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.ECM_AUTO_VIEW, ADMIN_PERMISSION.ECM_AUTO_EDIT]),
      },
      {
        id: 1008,
        label: loc('admin.menu.unreliableCarrier'),
        link: getArmLegasyUrl('unreliableCarrier'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.ECM_CARRIER_VIEW, ADMIN_PERMISSION.ECM_CARRIER_EDIT]),
      },
      {
        id: 1009,
        label: loc('admin.menu.REORGANIZATION'),
        link: getArmLegasyUrl('REORGANIZATION'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.REORGANIZATION_EDIT,
          ADMIN_PERMISSION.REORGANIZATION_VIEW,
        ]),
      },
      {
        id: 1010,
        label: loc('admin.menu.OIL_PIN'),
        link: `${PATH.ARM}${PATH.PINCODES_OIL}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.OIL_PIN_EDIT, ADMIN_PERMISSION.OIL_OVD_VIEW]),
      },
      {
        id: 1011,
        label: loc('admin.menu.TOBACCO_PIN'),
        link: `${PATH.ARM}${PATH.PINCODES_TOBACCO}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.TOBACCO_PIN_EDIT, ADMIN_PERMISSION.TOBACCO_PIN_VIEW]),
      },
      {
        id: 1012,
        label: loc('admin.menu.ALCOHOL_PIN'),
        link: `${PATH.ARM}${PATH.PINCODES_ALCOHOL}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.ALCOHOL_PIN_EDIT, ADMIN_PERMISSION.ALCOHOL_PIN_VIEW]),
      },
      {
        id: 1013,
        label: loc('admin.menu.OIL_OVD'),
        link: `${PATH.ARM}${PATH.OVD_OIL}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.OIL_OVD_EDIT, ADMIN_PERMISSION.OIL_OVD_VIEW]),
      },
      {
        id: 1014,
        label: loc('admin.menu.TOBACCO_OVD'),
        link: `${PATH.ARM}${PATH.OVD_TOBACCO}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.TOBACCO_OVD_EDIT, ADMIN_PERMISSION.TOBACCO_OVD_VIEW]),
      },
      {
        id: 1015,
        label: loc('admin.menu.ALCOHOL_LICENSE'),
        link: `${PATH.ARM}${PATH.LICENSE_ALCOHOL}`,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.ALCOHOL_LICENSE_EDIT,
          ADMIN_PERMISSION.ALCOHOL_LICENSE_VIEW,
        ]),
      },
      {
        id: 1016,
        label: loc('admin.menu.ALCOHOL_SPIRIT'),
        link: getArmLegasyUrl('ALCOHOL_SPIRIT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.ALCOHOL_SPIRIT_PERCENT_EDIT,
          ADMIN_PERMISSION.ALCOHOL_SPIRIT_PERCENT_VIEW,
        ]),
      },
      {
        id: 1017,
        label: loc('admin.menu.EXCISE_RATE'),
        link: getArmLegasyUrl('EXCISE_RATE'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EXCISE_RATE_EDIT, ADMIN_PERMISSION.EXCISE_RATE_VIEW]),
      },
      {
        id: 1018,
        label: loc('admin.menu.BANNED_GOOD'),
        link: getArmLegasyUrl('BANNED_GOOD'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.BANNED_GOOD_EDIT, ADMIN_PERMISSION.BANNED_GOOD_VIEW]),
      },
      {
        id: 1019,
        label: loc('admin.menu.PLANNED_CHECKPOINT'),
        link: getArmLegasyUrl('PLANNED_CHECKPOINT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.PLANNED_CHECKPOINT_EDIT,
          ADMIN_PERMISSION.PLANNED_CHECKPOINT_VIEW,
        ]),
      },
      {
        id: 1020,
        label: loc('admin.menu.TAXPAYER_MARK'),
        link: getArmLegasyUrl('TAXPAYER_MARK'),
        externalLink: true,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.TAXPAYER_MARK_EDIT,
          ADMIN_PERMISSION.TAXPAYER_MARK_VIEW,
        ]),
      },
      {
        id: 1021,
        label: loc('admin.menu.OGD'),
        link: `${PATH.ARM}${PATH.OGD}`,
        disabled: !user.checkAdminPermission(ADMIN_PERMISSION.OGD_VIEW),
      },
      {
        id: 1022,
        label: loc('admin.menu.SMALL_RETAIL'),
        link: `${PATH.ARM}${PATH.SMALL_RETAIL}`,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.SMALL_RETAIL_OUTLET_EDIT,
          ADMIN_PERMISSION.SMALL_RETAIL_OUTLET_VIEW,
        ]),
      },
      {
        id: 1023,
        label: loc('admin.menu.QOLDAY'),
        link: `${PATH.ARM}${PATH.QOLDAY_REGISTRY}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.QOLDAY_VIEW]),
      },
      {
        id: 1024,
        label: loc('admin.menu.NOT_BIO_TAXPAYERS'),
        link: `${PATH.ARM}${PATH.NOT_BIO_TAXPAYERS}`,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.BIOMETRICS_NOT_REQUIRED_TAXPAYER_VIEW,
          ADMIN_PERMISSION.BIOMETRICS_NOT_REQUIRED_TAXPAYER_EDIT,
        ]),
      },
      {
        id: 1025,
        label: loc('admin.menu.BIO_RISKY'),
        link: `${PATH.ARM}${PATH.BIO_RISKY}`,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_VIEW,
          ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_EDIT,
        ]),
      },
      {
        id: 1026,
        label: loc('admin.menu.GOV_INST'),
        link: `${PATH.ARM}${PATH.GOV_INST}`,
        disabled: !user.checkAdminPermission([
          ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_VIEW,
          ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_EDIT,
        ]),
      },
    ],
  },
  {
    id: 11,
    label: loc('admin.menu.REPORT'),
    iconName: 'detail',
    childrenItems: [
      {
        id: 1101,
        label: loc('admin.menu.STATISTIC_REPORT'),
        link: getArmLegasyUrl('STATISTIC_REPORT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.STATISTIC_REPORT]),
      },
      {
        id: 1102,
        label: loc('admin.menu.REGISTERED_USER_REPORT'),
        link: getArmLegasyUrl('REGISTERED_USER_REPORT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.REGISTERED_USER_REPORT]),
      },
      {
        id: 1103,
        label: loc('admin.menu.SNT_BORDER_CROSSING_REPORT'),
        link: `${PATH.ARM}${PATH.SNT_BORDER_CROSSING_REPORT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.SNT_BORDER_CROSSING_REPORT]),
      },
      {
        id: 1104,
        label: loc('admin.menu.TRANSIT_REPORT'),
        link: getArmLegasyUrl('TRANSIT_REPORT'),
        externalLink: true,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.TRANSIT_REPORT]),
      },
      {
        id: 1105,
        label: loc('admin.menu.STATISTIC_DOCFLOW_REPORT'),
        link: `${PATH.ARM}${PATH.STATISTIC_DOCFLOW_REPORT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.STATISTIC_DOCFLOW_REPORT]),
      },
      {
        id: 1106,
        label: loc('common.menu.SALDO_REPORT'),
        link: `${PATH.ARM}${PATH.SALDO_REPORT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.SALDO_VIEW]),
      },
    ],
  },
  {
    id: 20,
    label: loc('admin.menu.EAEC_ACTIONS'),
    iconName: 'detail',
    disabled: !user.isAuthed,
    // childrenDirUp: true,
    childrenItems: [
      {
        id: 2001,
        label: loc('admin.menu.EAEC_DOCUMENTS_INPUT'),
        link: `${PATH.ARM}${PATH.EAEC_DOCUMENTS_INPUT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EEC_VIEW_TRACEABILITY_GOODS_INPUT_REQUEST]),
      },
      {
        id: 2002,
        label: loc('admin.menu.EAEC_DOCUMENTS_OUTPUT'),
        link: `${PATH.ARM}${PATH.EAEC_DOCUMENTS_OUTPUT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EEC_VIEW_TRACEABILITY_GOODS_OUTPUT_REQUEST]),
      },
      {
        id: 2003,
        label: loc('admin.menu.EAEC_OPERATIONS_INPUT'),
        link: `${PATH.ARM}${PATH.EAEC_OPERATIONS_INPUT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EEC_VIEW_OPERATIONS_INPUT_REQUEST]),
      },
      {
        id: 2004,
        label: loc('admin.menu.EAEC_OPERATIONS_OUTPUT'),
        link: `${PATH.ARM}${PATH.EAEC_OPERATIONS_OUTPUT}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EEC_VIEW_OPERATIONS_OUTPUT_REQUEST]),
      },
      {
        id: 2005,
        label: loc('admin.menu.EAEC_MONITORING'),
        link: `${PATH.ARM}${PATH.EAEC_MONITORING}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EEC_VIEW_MONITORING_REQUEST]),
      },
      {
        id: 2006,
        label: loc('admin.menu.EAEC_TRANSACTION'),
        link: `${PATH.ARM}${PATH.EAEC_TRANSACTION}`,
        disabled: !user.checkAdminPermission([ADMIN_PERMISSION.EEC_VIEW_TRANSACTION]),
      },
    ],
  },
  {
    id: 18,
    label: 'Development',
    iconName: 'detail',
    link: PATH.DEV,
    disabled: process.env.NODE_ENV === 'production',
  },
];
