import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getOvdOilColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('common.labels.iin'),
  },
  {
    name: 'ovdCode',
    label: loc('ovd.code'),
  },
  {
    name: 'kogd',
    label: loc('ovd.kogd'),
  },
  {
    name: 'address',
    label: loc('ovd.address'),
  },
  {
    name: 'petrolStationName',
    label: loc('ovd.oil.petrol_station_name'),
  },
  {
    name: 'carNumber',
    label: loc('ovd.oil.car_number'),
  },
  {
    name: 'markOfCar',
    label: loc('ovd.oil.mark_of_car'),
  },
  {
    name: 'messageType',
    label: loc('ovd.message_type'),
    getContent: (row) => (row.messageType ? loc(`ovd.messageType.${row.messageType}`) : ''),
  },
];
