import { FilterContentProps } from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput } from 'ui/common';

type Props = FilterContentProps;

export const PincodesAlcoholArmFilters: React.FC<Props> = ({ form, classNames: { columnClassName } }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="tin" label={loc('pincodes.table.title.alco_tin')}>
          <FormInput />
        </FormField>
        <FormField name="pinCode" label={loc('pincodes.table.title.pinCode')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="productName" label={loc('pincodes.table.title.alco_productName')}>
          <FormInput />
        </FormField>
        <FormField name="productionType" label={loc('pincodes.table.title.productType')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="spiritPercent" label={loc('pincodes.alcohol.table.title.spiritPercent')}>
          <FormInput />
        </FormField>
        <FormField name="tareCapacity" label={loc('pincodes.alcohol.table.title.tareCapacity')}>
          <FormInput />
        </FormField>
      </div>
    </Fragment>
  );
};
