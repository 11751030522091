import { FilterT2Tag } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getPincodesAlcoholColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.alco_tin'),
    sortable: true,
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
    sortable: true,
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.alco_productName'),
    sortable: true,
  },
  {
    name: 'productionType',
    label: loc('pincodes.table.title.productType'),
    sortable: false,
  },
  {
    name: 'spiritPercent',
    label: loc('pincodes.alcohol.table.title.spiritPercent'),
    sortable: false,
  },
  {
    name: 'tareCapacity',
    label: loc('pincodes.alcohol.table.title.tareCapacity'),
    sortable: false,
  },
];

export const getPincodesAlcoholFilterTags = ({ locale: { loc } }: { locale: LocaleStore }): Array<FilterT2Tag> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.alco_tin'),
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.alco_productName'),
  },
  {
    name: 'productionType',
    label: loc('pincodes.table.title.productType'),
  },
  {
    name: 'spiritPercent',
    label: loc('pincodes.alcohol.table.title.spiritPercent'),
  },
  {
    name: 'tareCapacity',
    label: loc('pincodes.alcohol.table.title.tareCapacity'),
  },
];
