import React, { FC, useEffect, useMemo } from 'react';
import s from '../../TaxpayerMainPage.module.scss';
import { LocaleStore } from 'services/l10n';
import { ButtonT2, TableColumn } from 'ui';
import { BoardButton, formatPrice } from '../../helpers';
import { getIn } from 'formik';
import { AppMode, DocDirection, PERMISSION } from 'common/constants';
import { useStore } from 'common/hooks';
import { AwpBoardFilter } from './AwpBoardFilter';
import { AppLink, Board } from 'common/components';
import { queryAwpSearch, useDataQuery } from 'rest';
import { formatFromDate } from 'services/date-time';
import { subMonths } from 'date-fns';
import { BoardComponentProps } from '../boardTypes';
import { UserStore } from 'common/stores/user-store';
import {
  AWP_FILTER_IN_WORK_STATUSES,
  AWP_FILTER_OUTBOUND_STATUSES,
  AWP_STATUS_CLASSES,
  AwpMode,
  AwpStatus,
  downloadAwpPdf,
  navigateToAwpPageLegacy,
  solveAwpDirection,
} from 'features/awp';

const initialPagination = { rows: 5 };
const getRowId = (row: any) => row?.awpId;

export const AwpBoard: FC<BoardComponentProps> = ({ className, boardParams, onLoad, opened }) => {
  const { locale, user } = useStore();
  const { loc } = locale;

  const initialFilter = useMemo(
    () => ({
      direction: 'INBOUND',
      dateFrom: formatFromDate(subMonths(new Date(), 5)),
      dateTo: formatFromDate(new Date()),
    }),
    []
  );

  const queryProps = useDataQuery(
    queryAwpSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      order: 'DESC',
      sort: 'date',
    },
    {
      skipInitialRequest: true,
      preProcessParams: (variables) => {
        console.log({ variables });
        const { filter } = variables || {};
        let newFilter: any = {};
        if (filter.registrationNumber) {
          newFilter['registrationNumber'] = filter.registrationNumber;
        } else {
          const { direction, ...restFilter } = filter || {};
          newFilter = {
            ...restFilter,
            direction: direction === 'IN_WORK' ? undefined : direction,
          };
          if (direction === 'IN_WORK') {
            newFilter['statuses'] = AWP_FILTER_IN_WORK_STATUSES;
          }
          if (direction === 'OUTBOUND') {
            newFilter['statuses'] = AWP_FILTER_OUTBOUND_STATUSES;
          }
        }
        console.log({ filter, newFilter });
        return { ...variables, filter: newFilter };
      },
    }
  );

  const columns = getAwpBoardColumns({ locale, user });

  const menuButtons: Array<BoardButton> = [
    {
      id: 1,
      label: loc('common.button.open'),
      onClick: async (row) => {
        navigateToAwpPageLegacy(AwpMode.show, getRowId(row));
      },
      disabled: (row) => {
        const enabled = user.checkPermission(PERMISSION.INVOICE_VIEW);
        return !enabled;
      },
    },
    {
      id: 24,
      label: loc('common.button.pdf'),
      onClick: async (row) => {
        return downloadAwpPdf([getRowId(row)], AppMode.TAXPAYER);
      },
      disabled: (row) => {
        const enabled = user.checkPermission(PERMISSION.SNT_VIEW);
        return !enabled;
      },
    },
  ];

  useEffect(() => {
    if (opened) {
      queryProps.refetch();
    }
  }, [opened]); // eslint-disable-line

  useEffect(() => {
    if (queryProps.data) {
      onLoad();
    }
  }, [queryProps.data]); // eslint-disable-line

  return (
    <Board
      columns={columns}
      getRowId={getRowId}
      menuButtons={menuButtons}
      queryProps={queryProps}
      getRows={(data) => data?.rows}
      filterComponent={AwpBoardFilter}
      renderLinkButton={() => (
        <AppLink legasyTo={boardParams.legasylink}>
          <ButtonT2 variant="outlined" className={s.toJournalButton}>
            {loc('common.button.toJournal')}
          </ButtonT2>
        </AppLink>
      )}
    />
  );
};

const getAwpBoardColumns = ({
  locale: { loc },
  user,
}: {
  locale: LocaleStore;
  user: UserStore;
}): Array<TableColumn> => [
  {
    name: 'number',
    label: '',
  },
  {
    name: 'status',
    label: '',
    getContent: (row) => {
      const status: AwpStatus = row.status || '';
      return row.status ? (
        <span className={AWP_STATUS_CLASSES[row.status as AwpStatus]}>{loc(`c.awp.status.${status}`)}</span>
      ) : (
        ''
      );
    },
    getTitle: (row) => {
      return row.status ? loc(`c.awp.status.${row.status}`) : '';
    },
  },
  {
    name: 'contragent',
    label: '',
    getContent: (row) =>
      solveAwpDirection(row, user) === DocDirection.INBOUND ? getIn(row, 'senderName') : getIn(row, 'recipientName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: '',
    getContent: (row) => row.registrationNumber || loc('common.noNumber'),
    width: 300,
  },
  {
    name: 'totalSumWithTax',
    label: '',
    getContent: (...args) => formatPrice(args[2]),
    showTotal: true,
  },
  {
    name: 'date',
    label: '',
  },
];
