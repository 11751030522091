import { DataQueryFunction, downloadFile, httpClient, toPaginationParams } from '../../../rest';
import { clearEmptyFields } from '../../../common/utils';
import { API_URL } from '../../../common/constants';

export const queryObjectsOvdTobaccoSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination, order, sort } = variables || {};

  let criteria: any = JSON.stringify(clearEmptyFields({ ...filter }));

  const res = await httpClient.get(`dict/ovd/tobacco/search`, {
    params: clearEmptyFields({
      criteria,
      ...toPaginationParams(pagination),
      order,
      sort,
    }),
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};

export const queryObjectsOvdTobaccoExport: DataQueryFunction = (variables: any) => {
  const { filter = {}, pagination } = variables || {};
  return downloadFile({
    url: `${API_URL}/dict/ovd/tobacco/export`,
    method: 'GET',
    params: { criteria: JSON.stringify(clearEmptyFields(filter)), ...pagination },
  });
};
