import { API_URL, AppMode, DocDirection } from 'common/constants';
import { solveErrorMessage } from 'common/form/helpers';
import { getLegasyUrl } from 'common/helpers';
import { UserStore } from 'common/stores/user-store';
import { downloadFile } from 'rest';
import { LocaleStore } from 'services/l10n';
import { ToastService } from 'services/toasts';
import { AWP_FILTER_IN_WORK_STATUSES, AWP_FILTER_OUTBOUND_STATUSES, AwpMode } from './constants';
import { History } from 'history';
import { generatePath } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { AwpFormValues } from './types';

/**
 * Определяет направление документа АВР (Входящий, Исходящий);
 * awp - Данные АВР в журнале или данные формы АВР
 */
export const solveAwpDirection = (awp: any, user: UserStore): DocDirection.INBOUND | DocDirection.OUTBOUND => {
  //Для провкерки в журнале АВР
  if (awp.senderTin === user.currentUser?.currentUserTin) {
    return DocDirection.OUTBOUND;
  }
  //Для проверки в форме АВР
  if (Array.isArray(awp.senders)) {
    if ((awp as AwpFormValues).senders.some((sender) => sender.tin === user.currentUser?.currentUserTin)) {
      return DocDirection.OUTBOUND;
    }
  }
  return DocDirection.INBOUND;
};

/**
 * Перенавравление на страницу бланка АВР (в старом интерфейсе)
 */
export const navigateToAwpPageLegacy = (mode: AwpMode, awpId: string = '') => {
  window.location.assign(getLegasyUrl(`/awp/${mode}/${awpId}`));
};

/**
 * Перенавравление на страницу бланка (новые страницы)
 */
export const navigateToAwpPage = ({ mode, id, history }: { mode: AwpMode; id?: string; history: History }) => {
  if (mode === AwpMode.new) {
    history.push(generatePath(PATH.AWP_FORM_NEW, { mode }));
  } else if (id) {
    history.push(
      generatePath(PATH.AWP_FORM, {
        mode,
        id,
      })
    );
  }
};

export const downloadAwpPdf = async (ids: Array<string>, appMode?: AppMode) => {
  const queries: Promise<any>[] = [];
  ids.forEach((id) => {
    const url =
      appMode === AppMode.ARM ? `${API_URL}/admin/awp/printReport/${id}/PDF` : `${API_URL}/awp/printReport/${id}/PDF`;
    queries.push(
      downloadFile({
        url,
        method: 'GET',
      })
    );
  });

  try {
    const res = await Promise.all(queries);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAwpStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => {
  return AWP_FILTER_OUTBOUND_STATUSES.map((st) => ({
    value: st,
    label: loc(`awp.status.${st}`),
  }));
};

export const getAwpStatusInWorkOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => {
  return AWP_FILTER_IN_WORK_STATUSES.map((st) => ({
    value: st,
    label: loc(`awp.status.${st}`),
  }));
};

type Config = {
  ids: Array<string>;
  format: 'PDF' | 'XLSX';
  isAdmin?: boolean;
};

export const printReportAwp = async (config: Config) => {
  const { ids, isAdmin, format } = config;
  const queries: Promise<any>[] = [];
  ids.forEach((id) => {
    const url = isAdmin
      ? `${API_URL}/admin/awp/printReport/${id}/${format}`
      : `${API_URL}/awp/printReport/${id}/${format}`;
    queries.push(
      downloadFile({
        url,
        method: 'GET',
      })
    );
  });

  try {
    const res = await Promise.all(queries);
    return res;
  } catch (error) {
    throw error;
  }
};

export const exportAwpXml = async ({ ids, isAdmin }: { ids: Array<string>; isAdmin?: boolean }) => {
  const url = isAdmin ? `${API_URL}/admin/awp/downloadXml` : `${API_URL}/awp/downloadXml`;
  try {
    await downloadFile({
      url,
      method: 'GET',
      params: { 'ids[]': ids },
    });
  } catch (error) {
    ToastService.showError(solveErrorMessage(error));
    throw error;
  }
  return;
};

type ExportHistoryConfig = {
  awpId: string;
  isAdmin?: boolean;
};

export const exportAwpHistory = (config: ExportHistoryConfig) => {
  const { awpId, isAdmin } = config;
  const url = isAdmin ? `${API_URL}/admin/awp/historyExport` : `${API_URL}/awp/historyExport`;

  return downloadFile({
    url,
    method: 'GET',
    params: { awpId },
  });
};
