import { FilterT2ContentProps } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FormFieldT2, FormInputT2 } from 'ui';

type Props = FilterT2ContentProps;

export const ObjectsOvdTobaccoTaxpayerFilterMain: React.FC<Props> = ({ classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { mainWrapper } = classNames || {};

  return (
    <div className={mainWrapper}>
      <FormFieldT2 style={{ width: '450px' }} name="tin" noMargin>
        <FormInputT2 placeholder={loc('common.labels.iin')} icon="search" />
      </FormFieldT2>
      <FormFieldT2 style={{ marginLeft: '10px' }} name="ovdCode" noMargin>
        <FormInputT2 placeholder={loc('ovd.code')} />
      </FormFieldT2>
    </div>
  );
};
