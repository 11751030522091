import { JournalButton, JournalT2 } from 'common/components/journal';
import { findErrorData } from 'common/form/helpers';
import { getLegasyUrl } from 'common/helpers';
import { useStore } from 'common/hooks';
import React, { Fragment, useMemo } from 'react';
import { useDataQuery } from 'rest';
import { SessionStorageService } from 'services/storage/StorageService';
import { ToastService } from 'services/toasts';
import { rowsOptionsTaxpayerFull } from 'ui';
import { ObjectsOvdTobaccoTaxpayerFiltersAdvanced } from './OvdTobaccoTaxpayerFilterAdvanced';
import { ObjectsOvdTobaccoTaxpayerFilterMain } from './OvdTobaccoTaxpayerFilterMain';
import { ReactComponent as DownloadIcon } from 'assets/icons/journal-buttons/download.svg';
import { getObjectsOvdTobaccoColumns, getObjectsOvdTobaccoFilterTags } from './getOvdTobaccoColumns';
import styles from './OvdTobaccoTaxpayer.module.scss';
import { queryObjectsOvdTobaccoExport, queryObjectsOvdTobaccoSearch } from '../api';

const initialPagination = { rows: 15 };
const initialOrder = 'desc';

const getRowId = (row: any) => row.id;

const getRows = (data: any) => (Array.isArray(data?.rows) ? data.rows : undefined);

const filterStorageKey = 'objectsOvdTobaccoTaxpayerFilter';

export const ObjectsOvdTobaccoTaxpayerPage: React.FC = () => {
  const { locale } = useStore();
  const { loc } = locale;

  const columns = useMemo(() => getObjectsOvdTobaccoColumns({ locale }), [locale]);

  const filterTags = useMemo(() => getObjectsOvdTobaccoFilterTags({ locale }), [locale]);

  const initialFilter = useMemo(() => SessionStorageService.get(filterStorageKey) || {}, []);

  const queryProps = useDataQuery(
    queryObjectsOvdTobaccoSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      order: initialOrder,
    },
    {
      preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }),
    }
  );

  const headerButtons: Array<JournalButton> = [
    {
      id: 'export',
      label: loc('common.button.exportExcel'),
      icon: DownloadIcon,
      onClick: () => {
        return queryObjectsOvdTobaccoExport({ filter: preProcessFilter(queryProps.filter) }).catch((error: any) => {
          const message = findErrorData(error);
          message?.length && ToastService.showError(message);
        });
      },
      variant: 'icon',
    },
  ];

  return (
    <Fragment>
      <JournalT2
        pageTitle={loc('common.menu.TOBACCO_OVD')}
        columns={columns}
        queryProps={queryProps}
        getRows={getRows}
        filterComponentMain={ObjectsOvdTobaccoTaxpayerFilterMain}
        filterComponentAdvanced={ObjectsOvdTobaccoTaxpayerFiltersAdvanced}
        headerButtons={headerButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        filterTags={filterTags}
        filterPanelType="aside"
        settingsModalWidth={520}
        showTotalRow
        paginationRowsOptions={rowsOptionsTaxpayerFull}
        oldVersionLink={{
          label: loc('common.oldVersion'),
          external: true,
          url: getLegasyUrl('/dictionaries/ovd-tobacco'),
        }}
        className={styles.container}
      />
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => {
  return filter;
};
