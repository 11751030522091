import { FilterT2Tag } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getPincodesOilColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.oil_tin'),
    sortable: true,
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
    sortable: true,
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.oil_productName'),
    sortable: true,
  },
  {
    name: 'productionType',
    label: loc('pincodes.table.title.productType'),
    sortable: false,
  },
];

export const getPincodesOilFilterTags = ({ locale: { loc } }: { locale: LocaleStore }): Array<FilterT2Tag> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.oil_tin'),
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.oil_productName'),
  },
  {
    name: 'productionType',
    label: loc('pincodes.table.title.productType'),
  },
];
