import { FilterT2ContentProps } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FormFieldT2, FormInputT2 } from 'ui';

type Props = FilterT2ContentProps;

export const PincodesAlcoholFiltersAdvanced: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { advancedWrapper } = classNames || {};

  return (
    <div className={advancedWrapper}>
      <FormFieldT2 name="tin" label={loc('pincodes.table.title.alco_tin')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="pinCode" label={loc('pincodes.table.title.pinCode')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="productName" label={loc('pincodes.table.title.alco_productName')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="productionType" label={loc('pincodes.table.title.productType')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="spiritPercent" label={loc('pincodes.alcohol.table.title.spiritPercent')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="tareCapacity" label={loc('pincodes.alcohol.table.title.tareCapacity')}>
        <FormInputT2 />
      </FormFieldT2>
    </div>
  );
};
