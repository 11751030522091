export enum Lang {
  RU = 'ru',
  KK = 'kk',
}

export const LANG_COOKIE_NAME = 'language';

export enum LocaleNames {
  /**
   * Общие локализации
   */
  COMMON = 'common',
  /**
   * Общие локализации для АРМ и страницы в АРМ
   */
  ADMIN = 'admin',
  /**
   * Общие страницы.
   */
  PAGES = 'pages',
  ESF = 'esf',
  SNT = 'snt',
  AWP = 'awp',
}

export const DEFAULT_LOCALES = [LocaleNames.COMMON, LocaleNames.PAGES, LocaleNames.ESF, LocaleNames.SNT, LocaleNames.AWP];
