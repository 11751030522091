import { clearEmptyFields } from 'common/utils';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryEcontractAutocompleteAwp: DataQueryFunction = async (variables: any) => {
  const { registrationNumber, supplierTins, customerTins } = variables || {};

  const res = await httpClient.get(`econtract/autocomplete/awp`, {
    params: clearEmptyFields({ registrationNumber, supplierTins, customerTins }),
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
