import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getPincodesOilColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('pincodes.table.title.oil_tin'),
    width: 450,
  },
  {
    name: 'pinCode',
    label: loc('pincodes.table.title.pinCode'),
  },
  {
    name: 'productName',
    label: loc('pincodes.table.title.oil_productName'),
  },
  {
    name: 'productionType',
    label: loc('pincodes.table.title.productType'),
  },
];
