import { DataQueryFunction, downloadFile, httpClient, DataMutationFunction, convertToFormDataDeep } from 'rest';
import { clearEmptyFields } from 'common/utils';
import { API_URL } from 'common/constants';

export const queryAdminDictLicenseAlcoholExport: DataQueryFunction = (variables: any) => {
  const { filter = {}, pagination } = variables;
  return downloadFile({
    url: `${API_URL}/admin/dict/license/alcohol/export`,
    method: 'GET',
    params: {
      criteria: JSON.stringify(clearEmptyFields(filter)),
      ...pagination,
    },
  });
};

export const mutationAdminDictionaryAlcoholSave: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body } = variables;

  const formData = convertToFormDataDeep({
    license: JSON.stringify(clearEmptyFields(body)),
  });

  const res = await httpClient.post('/admin/dict/license/alcohol/save', formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};

export const mutationAdminDictionaryLicenseAlcoholImportFromFile: DataMutationFunction = async (): Promise<any> => {
  const res = await httpClient.post('/admin/dict/license/alcohol/importFromFile');
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};

export const mutationAdminDictionaryLicenseAlcoholUploadFile: DataMutationFunction = async (
  variables = {}
): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormDataDeep(clearEmptyFields(body));

  const res = await httpClient.post('/admin/dict/license/alcohol/uploadFile', formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};

export const queryDictionaryLicenseAlcoholImportResult: DataQueryFunction = () => {
  return downloadFile({
    url: `${API_URL}/admin/dict/license/alcohol/getLastImportResult`,
    method: 'GET',
  });
};
