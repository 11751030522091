import { FilterT2ContentProps } from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FormFieldT2, FormInputT2, FormSelectT2 } from 'ui';
import { getOvdMessageTypeOptions } from 'pages/ovd/helpers';

type Props = FilterT2ContentProps;

export const ObjectsOvdTobaccoTaxpayerFiltersAdvanced: React.FC<Props> = ({ classNames }) => {
  const locale = useLocale();
  const { loc } = locale;
  const messageTypeOptions = getOvdMessageTypeOptions(locale).filter(
    (option) => option.value === '' || ['REGISTRATION', 'DELETE'].includes(option.value)
  );

  const { advancedWrapper } = classNames || {};

  return (
    <div className={advancedWrapper}>
      <FormFieldT2 name="tin" label={loc('common.labels.iin')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="ovdCode" label={loc('ovd.code')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="kogd" label={loc('ovd.kogd')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="address" label={loc('ovd.address')}>
        <FormInputT2 />
      </FormFieldT2>
      <FormFieldT2 name="messageType" label={loc('ovd.message_type')}>
        <FormSelectT2 options={messageTypeOptions} />
      </FormFieldT2>
    </div>
  );
};
