import { JournalButton, JournalT2 } from 'common/components/journal';
import { solveErrorMessage } from 'common/form/helpers';
import { getLegasyUrl } from 'common/helpers';
import { useStore } from 'common/hooks';
import React, { Fragment, useMemo } from 'react';
import { useDataQuery } from 'rest';
import { queryDictLicenseAlcoholSearch, queryDictLicenseAlcoholExport } from '../api';
import { SessionStorageService } from 'services/storage/StorageService';
import { ToastService } from 'services/toasts';
import { rowsOptionsTaxpayerFull } from 'ui';
import { LicenseAlcoholTaxpayerFiltersAdvanced } from './LicenseAlcoholTaxpayerFilterAdvanced';
import { LicenseAlcoholTaxpayerFilterMain } from './LicenseAlcoholTaxpayerFilterMain';
import { ReactComponent as DownloadIcon } from 'assets/icons/journal-buttons/download.svg';
import { getLicenseAlcoholColumns, getLicenseAlcoholFilterTags } from './getLicenseAcloholColumns';
import styles from './LicenseAlcoholTaxpayer.module.scss';

const initialPagination = { rows: 15 };
const initialOrder = 'desc';

const getRowId = (row: any) => row.id;

const getRows = (data: any) => (Array.isArray(data?.rows) ? data.rows : undefined);

const filterStorageKey = 'licenseAlcoholTaxpayerFilter';

export const LicenseAlcoholTaxpayerPage: React.FC = () => {
  const { locale } = useStore();
  const { loc } = locale;

  const columns = useMemo(() => getLicenseAlcoholColumns({ locale }), [locale]);

  const filterTags = useMemo(() => getLicenseAlcoholFilterTags({ locale }), [locale]);

  const initialFilter = useMemo(() => SessionStorageService.get(filterStorageKey) || {}, []);

  const queryProps = useDataQuery(
    queryDictLicenseAlcoholSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      order: initialOrder,
    },
    {
      preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }),
    }
  );

  const exportJournal = async () => {
    try {
      const res = await queryDictLicenseAlcoholExport({
        pagination: queryProps.pagination,
        filter: queryProps.filter,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 'export',
      label: loc('common.button.exportExcel'),
      icon: DownloadIcon,
      onClick: () => {
        exportJournal();
      },
      variant: 'icon',
    },
  ];

  return (
    <Fragment>
      <JournalT2
        pageTitle={loc('common.menu.ALCOHOL_LICENSE')}
        columns={columns}
        queryProps={queryProps}
        getRows={getRows}
        filterComponentMain={LicenseAlcoholTaxpayerFilterMain}
        filterComponentAdvanced={LicenseAlcoholTaxpayerFiltersAdvanced}
        headerButtons={headerButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        filterTags={filterTags}
        filterPanelType="aside"
        settingsModalWidth={520}
        showTotalRow
        paginationRowsOptions={rowsOptionsTaxpayerFull}
        oldVersionLink={{
          label: loc('common.oldVersion'),
          external: true,
          url: getLegasyUrl('/dictionaries/license-alcohol'),
        }}
        className={styles.container}
      />
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => {
  return filter;
};
